import React from "react";
import CategoriesContainer from "./CategoriesContainer";
import { CatMainDiv } from "./Categories.styled";
import { useAuth } from "../../Auth/Auth";
import { MainComponentContainer } from "../../Global.styled";
import { useNavigate } from "react-router-dom";

const Categories = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  if (!auth.loggedIn) {
    navigate("/login");
  }
  return (
    <MainComponentContainer>
      {auth.loggedIn ? (
        <>
          {/* <div className="text-center font-semibold text-4xl">
            Handle Categories and the PDF Files
          </div> */}
          <CatMainDiv>
            <CategoriesContainer />
          </CatMainDiv>
        </>
      ) : (
        <div className="text-center font-bold text-xl">
          <p>Login please</p>
        </div>
      )}
    </MainComponentContainer>
  );
};

export default Categories;
