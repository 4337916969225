import styled, { keyframes } from "styled-components";

export const UserStatContainer = styled.div`
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px;
  @media screen and (max-width: 720px) {
    flex-direction: row;
    border: 1px solid lightblue;
    border-radius: 10px;
  }
`;
export const BarChartsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 720px) {
    margin-left: 5rem;
  }
`;
export const UserDataTableContainer = styled.div`
  /* max-width: 1200px; */
  max-width: "100%";
  margin-left: 20px;
  margin-top: 20px;
  @media (max-width: 720px) {
    margin: 10px;
  }
`;

export const CatsPDFsBarchartContainer = styled.div`
  display: flex;
  gap: 40px;

  @media screen and (max-width: 720px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const BlueborderBoxContainer = styled.div`
  border: 1px solid lightblue;
  border-radius: 10px;
  flex: 1;

  @media screen and (max-width: 720px) {
    width: 100%;
  }
`;
export const ReportCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  justify-content: center;
  @media screen and (max-width: 720px) {
    display: flex;
    justify-content: center;
  }
`;

export const ProfileImageContainer = styled.div`
  /* border: 1px solid red; */
`;
export const ProfileMainDataContainer = styled.div`
  /* border: 1px solid green; */
  min-width: 80%;
`;
export const UserDetailsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 6;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
  @media screen and (max-width: 720px) {
    flex-direction: column;
  }
`;
export const IconText = styled.div`
  text-align: left;
  display: flex;
  gap: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  color: gray;

  align-items: center;
  background: lightblue;
  height: 35px;
  padding: 10px 15px;
  border-radius: 32px;
  /* border: 1px solid darkblue; */
`;
const shine = keyframes`
  0% {
    background-position: -200px;
  }
  100% {
    background-position: calc(200px + 100%);
  }
`;

export const IconTextSubed = styled.div`
  text-align: left;
  display: flex;
  gap: 5px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;

  align-items: center;
  height: 35px;
  padding: 10px 15px;
  border-radius: 32px;
  /* border: 1px solid darkblue; */

  position: relative;
  overflow: hidden;
  color: #e63e6d;
  background-color: #ffd700;
  font-weight: bold;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      45deg,
      transparent 0%,
      rgba(255, 255, 255, 0.5) 50%,
      transparent 100%
    );
    background-repeat: no-repeat;
    background-size: 200px;
    animation: ${shine} 10s linear infinite;
  }
`;

export const PDFButtonsContainer = styled.div`
  margin: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: auto;
  padding: 7px;
  overflow-y: scroll;
  @media screen and (max-width: 720px) {
    width: 90%;
  }
`;

export const PDFButton = styled.button`
  padding: 10px;
  border-radius: 20px;
  font-size: large;
  width: 100%;
  letter-spacing: 1.5px;
  border: 2px solid transparent;
  margin: 4px 0px;
  transition: box-shadow 0.3s ease;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  &:hover {
    /* border: thin gray; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
`;
export const PDFEngagementDataContainer = styled.div`
  width: 65%;
  margin: 20px;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  @media screen and (max-width: 720px) {
    width: 90%;
  }
`;

export const EngagementReportCard = styled.div`
  border-radius: 15px;
  border: 1px solid lightblue;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
`;
export const WindowgridWrapper = styled.div`
  margin-top: 30px;
  margin: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-auto-rows: 100px;
  grid-gap: 20px;
  @media screen and (max-width: 720px) {
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(3, 150px);
  }
`;

//usersSummary styles

export const UserActivityOverviewContainer = styled.div`
  /* border: 1px solid red; */
  margin-bottom: 50px;
  z-index: 0;
`;
export const UsersSummaryMainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  align-items: center;
  gap: 20px;
  @media screen and (min-width: 1024px) and (max-width: 1366px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const UsersSummaryBlock = styled.div`
  box-sizing: border-box;
  width: 280px;
  height: 100px;
  border: 1px solid white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  backdrop-filter: blur(6px);
  border-radius: 17px;
  text-align: center;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: space-around;
  user-select: none;
  font-weight: bold;
  &:hover {
    border: 1px solid darkblue;
    transform: scale(1.03);
  }
  @media screen and (max-width: 720px) {
    margin: auto;
    width: 100%;
  }
`;

export const blockh2 = styled.div`
  margin-bottom: 10px;
`;

export const blockp = styled.div`
  margin: 0;
`;
export const TextWithBg = styled.div`
  padding: 7px;
  width: 75px;
  border-radius: 6px;
  color: white;
`;
