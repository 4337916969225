import React from "react";
import { useAuth } from "../../Auth/Auth";
import { MainComponentContainer } from "../../Global.styled";
import { useNavigate } from "react-router-dom";
import UserDataTable from "./UserDataTable";
import {
  UserActivityOverviewContainer,
  UserDataTableContainer,
} from "./index.styled";
import UsersSummary from "./UsersSummary";
// import UnauthorizedPage from "../../pages/UnauthorizedPage";
import Unauthorized from "../../pages/UnauthorizedPage";

const Dashboard = () => {
  const navigate = useNavigate();
  const { loggedIn, access_token } = useAuth();

  // useEffect(() => {
  //NO useEffect- leads to unusual redirection because of async to '/login' often.
  if (!loggedIn && !access_token) {
    navigate("/login");
  }
  // }, [loggedIn, access_token, navigate]);

  return (
    <MainComponentContainer>
      {loggedIn ? (
        <div>
          <UserDataTableContainer>
            <UserActivityOverviewContainer>
              <UsersSummary />
            </UserActivityOverviewContainer>

            <UserDataTable />
          </UserDataTableContainer>
        </div>
      ) : (
        <div>
          <Unauthorized />
        </div>
      )}
    </MainComponentContainer>
  );
};

export default Dashboard;
