import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";
import { useAuth } from "./Auth";
import { useStateContext } from "../contexts/ContextProvider";
import API from "../API";

const Login = () => {
  const { setActiveMenu } = useStateContext();
  const auth = useAuth();
  const navigate = useNavigate();
  const [adminEmail, setAdminEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = await API.login(adminEmail, password);
    setLoading(false);
    if (token) {
      auth.login(token);
      // auth.setLoggedIn(true);
      navigate("/", { replace: true });
    } else {
      toast.error("Invalid Credentials.");
    }
  };

  useEffect(() => {
    if (!auth.loggedIn) {
      setActiveMenu(false);
    }
  });

  return (
    <div className="mt-20">
      <div className="flex flex-col  items-center justify-center">
        <div className="w-full max-w-md bg-white rounded-lg shadow-md p-6">
          <h2 className="text-2xl font-bold text-gray-900 select-none mb-4">
            Admin Login
          </h2>
          <form className="flex flex-col" onSubmit={handleLogin}>
            <input
              required
              type="email"
              className="bg-gray-100 text-gray-900 border-0 rounded-md p-2 mb-4 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
              placeholder="Email address"
              onChange={(e) => setAdminEmail(e.target.value)}
            />
            <input
              required
              type="password"
              className="bg-gray-100 text-gray-900 border-0 rounded-md p-2 mb-4 focus:bg-gray-200 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="flex items-center justify-between flex-wrap"></div>
            <button
              type="submit"
              disabled={loading}
              className="bg-gradient-to-r from-indigo-500 to-blue-500 text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150"
            >
              {loading ? `Logging in...` : `Login`}
            </button>
          </form>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Login;
