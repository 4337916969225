import React from "react";
import { BiCalendarCheck } from "react-icons/bi";

const SaudiTime = ({ dateString, icon, time_new_line }) => {
  // Check if dateString is valid
  if (!dateString) {
    // Return an error message, empty content, or a default value
    // Replace 'Invalid date' with whatever fallback content you prefer
    const invalidContent =
      icon === false ? (
        "Invalid date"
      ) : (
        <>
          <BiCalendarCheck size={22} /> Invalid date
        </>
      );
    return invalidContent;
  }

  // Parse the date string into a Date object
  const date = new Date(dateString + " +0000");
  // console.log(dateString, date)

  // Check if the date is valid
  if (isNaN(date)) {
    // Return an error message, empty content, or a default value
    // Replace 'Invalid date' with whatever fallback content you prefer
    const invalidContent =
      icon === false ? (
        "Invalid date"
      ) : (
        <>
          <BiCalendarCheck size={22} /> Invalid date
        </>
      );
    return invalidContent;
  }

  // Use Intl.DateTimeFormat to format the date in Saudi Arabia's timezone
  const saudiTime = new Intl.DateTimeFormat("en-US", {
    timeZone: "Asia/Riyadh",
    dateStyle: "medium",
    timeStyle: "medium",
  }).format(date);

  // Use Intl.DateTimeFormat to format the date in Saudi Arabia's timezone
  const saudiDateOnly = new Intl.DateTimeFormat("en-US", {
    timeZone: "Asia/Riyadh",
    dateStyle: "medium",
  }).format(date);

  const saudiTimeOnly = new Intl.DateTimeFormat("en-US", {
    timeZone: "Asia/Riyadh",
    timeStyle: "medium",
  }).format(date);
  if (time_new_line) {
    return (
      <>
        <div>{saudiDateOnly}</div>
        <div>{saudiTimeOnly}</div>
      </>
    );
  }

  if (icon === false) {
    return <>{saudiTime}</>;
  } else {
    return (
      <>
        <BiCalendarCheck size={22} /> {saudiTime}
      </>
    );
  }
};

export default SaudiTime;
