import React, { useEffect, useState } from "react";
import {
  MdAdd,
  MdAddCircle,
  MdDelete,
  MdDownload,
  MdEdit,
} from "react-icons/md";
import {
  CategoriesContainerDiv,
  CategoryBoxContainer,
} from "./Categories.styled";
import { FaEdit } from "react-icons/fa";
import {
  Button,
  CircularProgress,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Snippet,
  Switch,
  useDisclosure,
} from "@nextui-org/react";
import { PDFButton } from "./Categories.styled";
import { useAuth } from "../../Auth/Auth";
import API from "../../API";
import { Toaster, toast } from "react-hot-toast";
import NewCatCreate from "../Models/NewCatCreate";
import PDFUploader from "../Models/PDFUploader";
import { useStateContext } from "../../contexts/ContextProvider";
import EditCategory from "../Models/EditCategory";
import MakeActive from "../Models/MakeActive";
import { IoClose } from "react-icons/io5";
// import { TbStatusChange } from "react-icons/tb";
// import PDFStatus from "../Models/PDFStatus";

const CategoriesContainer = () => {
  const { access_token } = useAuth();
  const { refresh, setRefresh, activeMenu } = useStateContext();
  const [Categories, setCategories] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentCategory, setcurrentCategory] = useState(null); //sending to PDFUploader
  const [newFileName, setNewFileName] = useState("");
  const [operation, setOperation] = useState(null);

  const [isCreateModalOpen, setisCreateModalOpen] = useState(false);
  const [isPdfUploaderOpen, setisPdfUploaderOpen] = useState(false);
  const [isCatEditOpen, setisCatEditOpen] = useState(false);
  const [isCategoryActive, setisCategoryActive] = useState(false);
  const [isActiveModalOpen, setisActiveModalOpen] = useState(false);
  // const [isPDFStatusModalOpen, setisPDFStatusModalOpen] = useState(false);
  const [pdfSelect, setPdfSelect] = useState({ name: "", id: "" });

  const [loadingMsg, setLoadingMsg] = useState(null);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  useEffect(() => {
    const fetchCategories = async () => {
      if (access_token) {
        try {
          setLoadingMsg("Loading");
          const res = await API.getCategories(access_token);
          setLoadingMsg(null);
          setCategories(res);
        } catch (error) {
          setLoadingMsg(null);
          toast.error("Failed to get the categories!");
          console.error("Error in getting categories ", error);
        }
      }
    };
    fetchCategories();
  }, [access_token, refresh]);

  const handleEditFile = (name, uuid) => {
    setOperation("EditFile");
    setSelectedFile({ name, uuid });
    onOpen();
  };

  const handleDeleteFile = (name, uuid) => {
    setOperation("DeleteFile");
    setSelectedFile({ name, uuid });
    onOpen();
  };

  const handleCloseModal = (onClose) => {
    onClose();
    setSelectedFile(null);
    setNewFileName("");
    setOperation(null);
  };
  const handleDone = async (onClose) => {
    if (selectedFile) {
      if (operation === "EditFile") {
        if (newFileName.length > 0 && newFileName !== selectedFile?.name) {
          try {
            setLoadingMsg("Renaming");
            const res = await API.editFile(
              access_token,
              selectedFile.uuid,
              newFileName
            );
            setSelectedFile(null);
            setOperation(null);
            setNewFileName("");
            setLoadingMsg(null);
            onClose();
            if (res.status === 200) {
              toast.success("PDF renamed successfully");
            } else {
              console.error("Failed to rename PDF", res.message);
            }
            setRefresh(refresh + 1);
          } catch (error) {
            setLoadingMsg(null);
            setSelectedFile(null);
            setOperation(null);
            setNewFileName("");
            onClose();
            console.error("cannot rename", error);
          }
        } else {
          if (newFileName.length < 1) {
            toast("Type something to rename! or Close");
          } else if (newFileName === selectedFile?.name) {
            toast("Same content cannot be renamed! or Close");
          }
        }
      } else if (operation === "DeleteFile") {
        try {
          setLoadingMsg("Deleting");
          const res = await API.deleteFile(access_token, selectedFile.uuid);
          if (res.status === 200) {
            toast.success("File Deleted");
            setLoadingMsg(null);
            setSelectedFile(null);
            setOperation(null);
            setNewFileName("");
          }
          setLoadingMsg(null);
          setRefresh(refresh + 1);

          onClose();
        } catch (error) {
          toast.error("Failed to DELETE PDF File");
          console.error("Error in deletion of pdf file ", error);
          setRefresh(refresh + 1);
          setLoadingMsg(null);
          onClose();
        }
      }
    }
  };

  const handlePDdfStatusChange = async (status, id, name) => {
    try {
      if (access_token) {
        setLoadingMsg(`Loading...`);
        await API.changePdfStatus(access_token, id, status);
        setLoadingMsg(`File set to ${status} state.`);
      }
    } catch (error) {
      setLoadingMsg("");
      toast.error("Cannot change the status of this file.");
      console.log("error in changing the status of a file.", error);
    }
    setLoadingMsg("");
    setRefresh(refresh + 1);
  };
  return (
    <>
      {loadingMsg !== null ? (
        <div className="flex h-[80vh] justify-center items-center">
          <div className="flex justify-center shadow-md items-center rounded-md  flex-col gap-1 p-2 border">
            <span>
              <CircularProgress size="lg" label={loadingMsg} />
            </span>
          </div>
        </div>
      ) : (
        <CategoriesContainerDiv activeMenu={activeMenu}>
          <div
            onClick={() => {
              setisCreateModalOpen(true);
            }}
            className="text-xl select-none p-3 transition-all border-transparent hover:border-green-300 flex cursor-pointer flex-col items-center border shadow-lg rounded-lg justify-evenly gap-3 font-bold m-2 text-slate-800"
          >
            <div className="flex flex-col justify-normal items-center">
              <span>Add New Category</span>
              <span className="text-sm font-normal">
                Click to add a new category
              </span>
            </div>
            <div className="text-slate-500 rounded-full bg-slate-200 w-[100px] h-[100px] cursor-pointer flex justify-center items-center text-4xl font-bold">
              <MdAdd size={60} />
            </div>
          </div>
          {Categories?.categories?.map((item, index) => (
            <CategoryBoxContainer key={index}>
              <div className="text-xl flex items-start justify-start text-left cursor-pointer gap-3 font-bold m-2 text-slate-800">
                <div>
                  <Dropdown placement="right">
                    <DropdownTrigger>
                      <div className="flex text-left flex-col">
                        <span className="m-1 overflow-x-auto">
                          <span className="whitespace-nowrap">
                            {`${item?.name} `}
                            {item?.active ? (
                              <span className="dot active-dot"></span>
                            ) : (
                              <span className="dot inactive-dot"></span>
                            )}{" "}
                            <span className="text-sm font-medium text-slate-600">
                              &#40; {item?.count} &#41;
                            </span>
                          </span>
                        </span>
                        <span className="text-sm m-1 font-normal">
                          {item?.description}
                        </span>
                      </div>
                    </DropdownTrigger>

                    <DropdownMenu
                      aria-label="Static Actions"
                      disabledKeys={["delete"]}
                    >
                      <DropdownItem textValue="New file" key="new">
                        <span
                          onClick={() => {
                            setisPdfUploaderOpen(true);
                            setcurrentCategory({
                              name: item?.name,
                              id: item?.id,
                              desc: item?.description,
                            });
                          }}
                          className="flex items-center  select-none justify-between gap-2"
                        >
                          New file
                          <MdAddCircle size={20} />
                        </span>
                      </DropdownItem>
                      <DropdownItem>
                        <span className="flex items-center  select-none justify-between gap-2">
                          Active
                          <Switch
                            onClick={() => {
                              setSelectedCategory({
                                name: item?.name,
                                id: item?.id,
                                desc: item?.description,
                              });
                              setisCategoryActive(item?.active);
                              setisActiveModalOpen(true);
                            }}
                            isSelected={item?.active}
                            aria-label="Automatic updates"
                          />
                        </span>
                      </DropdownItem>
                      <DropdownItem key="copy" textValue="Copy name">
                        <div>
                          <Snippet symbol="">{item?.name}</Snippet>
                        </div>
                      </DropdownItem>
                      <DropdownItem
                        textValue="Edit name"
                        key="edit"
                        onClick={() => {
                          setisCatEditOpen(true);
                          setSelectedCategory({
                            name: item?.name,
                            id: item?.id,
                            desc: item?.description,
                          });
                        }}
                      >
                        <span className="flex items-center select-none justify-between gap-2">
                          Edit name
                          <MdEdit size={20} />
                        </span>
                      </DropdownItem>
                      <DropdownItem
                        textValue="Edit description"
                        key="edit decription"
                        onClick={() => {
                          setisCatEditOpen(true);
                          setSelectedCategory({
                            name: item?.name,
                            id: item?.id,
                            desc: item?.description,
                            editType: "description",
                          });
                        }}
                      >
                        <span className="flex items-center select-none justify-between gap-2">
                          Edit description
                          <MdEdit size={20} />
                        </span>
                      </DropdownItem>
                      <DropdownItem
                        key="delete"
                        className="text-danger"
                        color="danger"
                        textValue="Delete file"
                      >
                        <span className="flex items-center select-none justify-between gap-2">
                          Delete category
                          <MdDelete size={20} />
                        </span>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
              <Divider />
              <div className="flex flex-col gap-2 justify-center select-none mt-2 w-full items-stretch">
                {item?.pdf_files?.map((pdf, idx) => (
                  <span key={idx}>
                    <Dropdown>
                      <DropdownTrigger>
                        <PDFButton
                          onClick={() => {
                            setPdfSelect({
                              name: pdf?.pdf_name,
                              id: pdf?.pdf_id,
                            });
                          }}
                          style={{
                            border: `${pdf?.status ? "2px solid green" : ""}`,
                          }}
                        >
                          {pdf.pdf_name}
                        </PDFButton>
                      </DropdownTrigger>
                      <DropdownMenu
                        disabledKeys={["download"]}
                        aria-label="Static Actions"
                      >
                        <DropdownItem key="copy" textValue="Copy name">
                          <Snippet symbol="">{pdf.pdf_name}</Snippet>
                        </DropdownItem>
                        <DropdownItem textValue="Download file" key="download">
                          <span className="flex items-center select-none justify-between gap-2">
                            Download file <MdDownload size={20} />
                          </span>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            handlePDdfStatusChange(
                              !pdf?.status,
                              pdfSelect.id,
                              pdfSelect.name
                            )
                          }
                          key="pdf status change"
                          textValue="PDF state change"
                        >
                          <span className="flex items-center select-none justify-between gap-2">
                            Active File
                            <Switch
                              onValueChange={() => {
                                handlePDdfStatusChange(
                                  !pdf?.status,
                                  pdfSelect.id,
                                  pdfSelect.name
                                );
                              }}
                              isSelected={pdf?.status}
                              aria-label="Automatic updates"
                            />
                          </span>
                        </DropdownItem>
                        {/* <DropdownItem
                          onClick={() => {
                            setisPDFStatusModalOpen(true);
                            setSelectedFile({
                              name: pdf?.pdf_name,
                              uuid: pdf?.pdf_id,
                            });
                            setPdfSelect(pdf?.status);
                          }}
                          key="pdf status"
                          textValue="Edit name"
                        >
                          <span className="flex items-center select-none justify-between gap-2">
                            Set status
                            <TbStatusChange size={20} />
                          </span>
                        </DropdownItem> */}
                        <DropdownItem
                          onClick={() =>
                            handleEditFile(pdf?.pdf_name, pdf?.pdf_id)
                          }
                          key="edit"
                          textValue="Edit name"
                        >
                          <span className="flex items-center select-none justify-between gap-2">
                            Edit name
                            <MdEdit size={20} />
                          </span>
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            handleDeleteFile(pdf?.pdf_name, pdf?.pdf_id)
                          }
                          key="delete"
                          textValue="Delete file"
                          className="text-danger"
                          color="danger"
                        >
                          <span className="flex items-center select-none justify-between gap-2">
                            Delete file
                            <MdDelete size={20} />
                          </span>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </span>
                ))}
                {item?.pdf_files.length === 0 ? (
                  <div className="flex justify-center flex-col gap-2 items-center m-3 text-[darkblue] font-semibold">
                    <p>Add the first PDF to this Category</p>
                    <div
                      onClick={() => {
                        setisPdfUploaderOpen(true);
                        setcurrentCategory({ name: item?.name, id: item?.id });
                      }}
                      className="text-slate-500 rounded-full cursor-pointer bg-slate-200 w-[50px] h-[50px] flex justify-center items-center text-xl font-semibold"
                    >
                      <MdAdd size={60} />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </CategoryBoxContainer>
          ))}

          <Modal
            hideCloseButton
            backdrop="blur"
            isOpen={isOpen}
            onOpenChange={onOpenChange}
          >
            <ModalContent>
              {(onClose) => (
                <>
                  <ModalHeader className="flex flex-col gap-1">
                    {operation === "EditFile" ? (
                      <span>
                        Editing File :{" "}
                        <Snippet
                          style={{ backgroundColor: "transparent" }}
                          symbol=""
                        >
                          <span className="font-bold text-xl">
                            {selectedFile?.name}
                          </span>
                        </Snippet>
                      </span>
                    ) : operation === "DeleteFile" ? (
                      <span>
                        Deleting File :{" "}
                        <strong className="font-bold text-red-600">
                          {selectedFile.name ? selectedFile.name : ""}
                        </strong>
                      </span>
                    ) : null}
                  </ModalHeader>
                  <ModalBody>
                    <div className="absolute top-0 right-0 m-1">
                      <Button
                        onPress={() => handleCloseModal(onClose)}
                        variant="light"
                        isIconOnly
                      >
                        <IoClose size={25} />
                      </Button>
                    </div>
                    {operation === "EditFile" ? (
                      <div>
                        <Input
                          readOnly={false}
                          autoFocus
                          placeholder={`Rename File`}
                          variant="bordered"
                          value={newFileName}
                          onChange={(e) => {
                            setNewFileName(e.target.value);
                          }}
                          endContent={
                            <FaEdit className="text-2xl pointer-events-none text-slate-700 flex-shrink-0" />
                          }
                        />
                      </div>
                    ) : operation === "DeleteFile" ? (
                      <div>
                        <p>
                          Are you sure you want to DELETE this File? This action
                          cannot be Undone.
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </ModalBody>

                  <ModalFooter>
                    <Button
                      color="default"
                      variant="light"
                      onPress={() => handleCloseModal(onClose)}
                    >
                      Close
                    </Button>
                    <Button
                      color={operation === "DeleteFile" ? "danger" : "primary"}
                      onPress={() => handleDone(onClose)}
                    >
                      Done
                    </Button>
                  </ModalFooter>
                </>
              )}
            </ModalContent>
          </Modal>
          <NewCatCreate
            isCreateModalOpen={isCreateModalOpen}
            setisCreateModalOpen={setisCreateModalOpen}
          />
          <PDFUploader
            isOpen={isPdfUploaderOpen}
            setisOpen={setisPdfUploaderOpen}
            category={currentCategory}
          />
          <EditCategory
            isOpen={isCatEditOpen}
            setisOpen={setisCatEditOpen}
            category={selectedCategory}
          />
          <MakeActive
            isOpen={isActiveModalOpen}
            setisOpen={setisActiveModalOpen}
            isActiveCategory={isCategoryActive}
            setIsActiveCategory={setisCategoryActive}
            category={selectedCategory}
          />
          {/* <PDFStatus
            isOpen={isPDFStatusModalOpen}
            setisOpen={setisPDFStatusModalOpen}
            selectedFile={selectedFile}
            pdfSelect={pdfSelect}
          /> */}
        </CategoriesContainerDiv>
      )}
      <Toaster />
    </>
  );
};

export default CategoriesContainer;
