import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { Tooltip, IconButton } from "@mui/material";
import Zoom from "@mui/material/Zoom";

import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { useAuth } from "../Auth/Auth";

const Sidebar = () => {
  const { loggedIn } = useAuth();
  const navigate = useNavigate();
  const { activeMenu, setActiveMenu, screenSize } = useStateContext();

  const handleCloseSideBar = () => {
    //for smaller screen closes by default
    if (activeMenu && screenSize <= 900) {
      setActiveMenu(false);
    }
  };
  const handleLinkClick = (event) => {
    if (!loggedIn) {
      event.preventDefault();
      navigate("/login", { replace: true });
    }
    handleCloseSideBar();
  };
  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700  hover:bg-light-gray m-2";

  return (
    <div className="ml-3 h-screen shadow-lg select-none md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight text-slate-900"
            >
              {/* Logo */} <span>Tmyyoz</span>
            </Link>
            {screenSize <= 900 && (
              <Tooltip
                TransitionComponent={Zoom}
                enterDelay={500}
                title="Menu-Close"
                arrow
              >
                <IconButton
                  className="text-xl mt-4 p-3  block"
                  style={{ color: "darkblue" }}
                  onClick={() => setActiveMenu((prev) => !prev)}
                >
                  <AiOutlineCloseCircle size={25} />
                </IconButton>
              </Tooltip>
            )}
          </div>
          <div className="mt-10 ">
            {links.map((item) => (
              <div key={item.title}>
                <p className="text-gray-400  m-3 mt-4 uppercase">
                  {item.title}
                </p>
                {item.links.map((link) => (
                  <NavLink
                    to={`/${link.link}`}
                    key={link.name}
                    onClick={handleLinkClick}
                    style={({ isActive }) => ({
                      backgroundColor: isActive ? "darkblue" : "",
                    })}
                    className={({ isActive }) =>
                      isActive ? activeLink : normalLink
                    }
                  >
                    {link?.icon}
                    <span className="capitalize ">{link?.name}</span>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
        </>
      )}

      {/* <div className="flex justify-center relative sm:top-[55%] lg:top-[40%] gap-3 flex-col items-center">
        {location.pathname === "/" ? (
          <div>
            <TextField //fetching the number of users-Page
              required
              id="outlined-number"
              label="Pages "
              type="number"
              // value="1"
              InputProps={{ inputProps: { min: 1 } }}
              onChange={(e) => setPageChange(e.target.value)}
            />
            <TextField //fetching the number of users-Limit
              required
              id="outlined-number"
              label="Limit of Users to Fetch"
              type="number"
              // value="10"
              InputProps={{ inputProps: { min: 1 } }}
              onChange={(e) => setLimitChange(e.target.value)}
            />
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: "80%",
                backgroundColor: "darkblue",
                color: "white",
                margin: "10px 0px",
              }}
              onClick={handleSetPageLimit}
            >
              Fetch Data
            </Button>
          </div>
        ) : (
          <div></div>
        )}
      </div> */}
    </div>
  );
};

export default Sidebar;
