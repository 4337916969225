import React, { useEffect } from "react";
import { useStateContext } from "../contexts/ContextProvider";

import { AiOutlineMenu } from "react-icons/ai";
import { UserProfile } from ".";
import { Fade, IconButton, Tooltip } from "@mui/material";
import { useAuth } from "../Auth/Auth";
// import { useAuth } from '../Auth/Auth';

const Navbar = () => {
  const { activeMenu, setActiveMenu, screenSize } = useStateContext();
  const { loggedIn } = useAuth();
  const isMenuActive = localStorage.getItem("activeMenu");

  // useEffect(() => {
  //   //default_hide and hide_after_click automatically for smaller devices
  //   const handleResize = () => setScreenSize(window.innerWidth);
  //   window.addEventListener("resize", handleResize);
  //   handleResize();
  //   return () => window.removeEventListener("resize", handleResize);
  // }, [setScreenSize]);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(isMenuActive === "true");
    }
  }, [screenSize, setActiveMenu, isMenuActive]);

  return (
    <>
      <div className="flex shadow-md justify-between items-center p-2 bg-white sidebar w-full relative">
        <Tooltip
          TransitionComponent={Fade}
          enterDelay={500}
          title={`${activeMenu ? "Close" : "Open"} menu`}
        >
          <span>
            <IconButton
              disabled={!loggedIn}
              onClick={() => {
                setActiveMenu((prev) => {
                  localStorage.setItem("activeMenu", !prev);
                  return !prev;
                });
              }}
              className="rounded-full"
            >
              {<AiOutlineMenu />}
            </IconButton>
          </span>
        </Tooltip>
        <div>
          <UserProfile />
        </div>
      </div>
    </>
  );
};

export default Navbar;
