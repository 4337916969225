import { Button } from "@nextui-org/react";
import React from "react";
import { Link } from "react-router-dom";
import image from "../assets/00cc9ce10f79e800abd9157cd1c8174e.jpg";

const UnauthorizedPage = () => {
  return (
    <div>
      <div className="flex gap-3 flex-col items-center justify-center min-h-screen text-gray-600 bg-gray-100">
        <img src={image} alt="" className="mix-blend-multiply" />
        {/* <h1 className="text-9xl font-bold">401</h1> */}
        <p className="mt-2 text-xl">
          You do not have the necessary permissions to view this page.
        </p>
        <p className="mt-2 text-md">This page is not publically available.</p>
        <p className="mt-2 text-md">To access it please Login first.</p>
        <div className="m-6">
          <Link to="/login">
            <Button variant="faded" color="primary">
              Login
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedPage;
