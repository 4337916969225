import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Progress,
  useDisclosure,
} from "@nextui-org/react";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { CgNametag } from "react-icons/cg";
import { IoClose } from "react-icons/io5";
import { useAuth } from "../../Auth/Auth";
import API from "../../API";
import { useStateContext } from "../../contexts/ContextProvider";

const PDFUploader = ({ isOpen, setisOpen, category }) => {
  const { onOpenChange } = useDisclosure();
  const { access_token } = useAuth();
  const { refresh, setRefresh } = useStateContext();

  const [file, setFile] = useState(null);
  // const [FileResponse, setFileResponse] = useState(null);
  const [pdfName, setPdfName] = useState(undefined);
  const [err, setErr] = useState(false);
  const [msg, setMsg] = useState(null);
  const [progress, setProgress] = useState({
    started: false,
    ended: false,
    percent: 0,
  });

  const handleCloseModal = (onClose) => {
    if (!progress.started || err) {
      setisOpen(false);
      onClose();
      setFile(null);
      setMsg(null);
      setProgress({ started: false, percent: 0 });
    } else {
      return;
    }
  };

  const handleUpload = async () => {
    if (!file) {
      setMsg("No file selected!");
      return;
    }
    if (file.type !== "application/pdf") {
      setMsg("Please select a PDF file.");
      return;
    }
    if (!pdfName) {
      setMsg("Please enter the name of the PDF.");
      return;
    }
    const formData = new FormData();
    formData.append("file", file);
    formData.append("category_id", category.id);
    setMsg("Uploading...");
    setProgress((prev) => {
      return { ...prev, started: true };
    });
    try {
      setErr(false);
      //showing the upload progress bar will be more complex in the case of using the API.<function>
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/upload-file`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            setProgress((prev) => {
              return { ...prev, percent: progressEvent.progress * 100 };
            });
          },
          headers: {
            Authorization: `Bearer ${access_token}`,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const addFileResponse = await API.addFile(
        access_token,
        category?.id,
        pdfName,
        res?.data?.file_url
      );

      console.log("file url response ", addFileResponse);
      if (addFileResponse) {
        // success: close the modal and show success message and reset all states
        setMsg("Upload Successful");
        setisOpen(false);
        setFile(null);
        setProgress({ started: false, percent: 0 });
        setErr(false);
        setRefresh(refresh + 1);
      } else {
        setMsg("Error adding the uploaded file");
        setErr(true);
      }
    } catch (error) {
      setMsg("Upload Failed");
      setErr(true);
      console.error("Error in sending file ", error);
    }
  };
  return (
    <div>
      <>
        <Modal
          hideCloseButton
          backdrop="blur"
          isOpen={isOpen}
          onOpenChange={onOpenChange}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  {`Adding PDF to ${category?.name}`}
                </ModalHeader>
                <ModalBody>
                  <div className="absolute top-0 right-0 m-1">
                    <Button
                      onPress={() => handleCloseModal(onClose)}
                      variant="light"
                      isIconOnly
                    >
                      <IoClose size={25} />
                    </Button>
                  </div>
                  <div className="grid w-full max-w-xs items-center gap-1.5">
                    <label className="text-sm text-gray-400 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                      Upload PDF File
                    </label>
                    <input
                      type="file"
                      onChange={(e) => setFile(e.target.files[0])}
                      className="flex h-10 w-full rounded-md border border-input bg-white px-3 py-2 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
                    />
                  </div>
                  <>
                    <Input
                      onChange={(e) => {
                        setPdfName(e.target.value);
                      }}
                      endContent={
                        <CgNametag className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                      }
                      label="PDF Name"
                      placeholder="Enter the name of the File"
                      variant="faded"
                    />
                  </>

                  <div className="flex justify-center flex-col items-center gap-3">
                    {progress.started && (
                      <Progress
                        aria-label="Loading..."
                        value={progress.percent}
                        className="max-w-md"
                      />
                    )}
                    {msg && (
                      <span className="transition-all text-lg font-semibold">
                        {msg}
                      </span>
                    )}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    variant="light"
                    onPress={() => handleCloseModal(onClose)}
                  >
                    Close
                  </Button>
                  <Button color="primary" onPress={handleUpload}>
                    Upload
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
        </Modal>
      </>
    </div>
  );
};

export default PDFUploader;
