import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../Auth/Auth";

const PageNotFound = () => {
  const { loggedIn } = useAuth();
  return (
    <div>
      <div className="flex gap-3 flex-col items-center justify-center min-h-screen text-gray-600 bg-gray-100">
        <h1 className="text-9xl font-bold">404</h1>
        <p className="mt-2 text-xl">Page Not Found</p>
        <p className="mt-2 text-md">
          The page you're looking for might have been removed, had its name
          changed, or is temporarily unavailable.
        </p>
        <p className="mt-2 text-md">
          Please check the URL for any errors and try again.
        </p>
        <div className="m-6">
          {loggedIn ? (
            <Link to="/">
              <Button variant="contained">Go Home</Button>
            </Link>
          ) : (
            <Link to="/login">
              <Button variant="contained">Login</Button>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
