import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [screenSize, setScreenSize] = useState(undefined);
  const [activeMenu, setActiveMenu] = useState(
    localStorage.getItem("activeMenu") === "true" || false
  );
  const [totalPages, setTotalPages] = useState(null);
  const [currentUser, setCurrentUser] = useState({
    id: "",
    p_id: "",
    name: "",
    email: "",
    phone: "",
    time: "",
  });
  const [refresh, setRefresh] = useState(0);
  return (
    <StateContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        screenSize,
        setScreenSize,
        currentUser,
        setCurrentUser,
        totalPages,
        setTotalPages,
        refresh,
        setRefresh,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
