import { LinearProgress } from "@mui/material";
import styled from "styled-components";
// import {media} from './utils/media'

export const MainComponentContainer = styled.div`
  /* border: 5px solid red; */
  height: auto;
  width: auto;
  @media (max-width: 720px) {
    /* border: 5px solid green; */
    margin-top: 100px;
  }
`;
export const LoaderDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 110;
  background-color: rgba(0, 0, 0, 0.25);
`;

export const MainCardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  @media (max-width: 720px) {
    margin-top: 150px;
  }
`;
export const UserDataContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -30px inset;
  @media screen and (max-width: 720px) {
    flex-direction: row;
    width: 112%;
  }
`;

export const PDFDetailsContainer = styled.div`
  display: flex;
  height: auto;
  margin: 20px;
  @media screen and (max-width: 720px) {
    flex-direction: column;
    width: 108%;
  }
`;

export const AccordionContainer = styled.div`
  max-height: 450px;
  overflow-y: auto;
  margin-left: 10px;
  overflow-x: hidden;
  width: 40%;
  margin: 20px;
  padding: 10px;
  @media screen and (max-width: 720px) {
    width: 90%;
  }
`;

export const ProfileHeaderContainer = styled.div`
  margin: 10px;
  margin-left: 100px;
  margin-top: 30px;
  display: flex;
  justify-content: center;
  cursor: default;
  width: 100%;
  min-height: 183px;
  @media screen and (max-width: 720px) {
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    min-height: 350px;
  }
`;
export const ProfileRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  height: 300px;
  border: 1px solid black;
  text-align: left;
`;

export const ReportCard = styled.div`
  background-color: lightblue;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  color: darkblue;
  width: 180px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  @media screen and (max-width: 720px) {
    /* border: 1px solid red; */
  }
`;

export const ReportText = styled.span`
  background-color: darkblue;
  color: white;
  min-width: 70px;
  text-align: center;
  padding: 5px;
  min-height: 35px;
  border-radius: 10px;
  margin-bottom: 10px;
`;
export const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 7,
  borderRadius: 5,
}));
