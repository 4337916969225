import React, { createContext, useContext, useState, useEffect } from "react";

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [access_token, setAccess_token] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const storedUser = localStorage.getItem("access_token");
    if (storedUser) {
      const { access_token } = JSON.parse(storedUser);
      setAccess_token(access_token);
      setLoggedIn(true);
    }
  }, []);

  const login = (access_token) => {
    setAccess_token(access_token);
    setLoggedIn(true);
    localStorage.setItem(
      "access_token",
      JSON.stringify({ access_token: access_token })
    );
  };
  const logout = () => {
    setAccess_token(null);
    setLoggedIn(false);
    localStorage.removeItem("access_token");
  };
  return (
    <AuthContext.Provider
      value={{
        setLoggedIn,
        setAccess_token,
        login,
        logout,
        loggedIn,
        access_token,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
