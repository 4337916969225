import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionItem,
  Button,
  CircularProgress,
  ScrollShadow,
} from "@nextui-org/react";
import API from "../../API";
import { useAuth } from "../../Auth/Auth";
import { Toaster, toast } from "react-hot-toast";
import ReactApexChart from "react-apexcharts";

const data = [
  {
    title: "New users over 5 days",
    value: "new_users_over_last_5_days",
    key: "new_users_count",
    type: "user",
  },
  {
    title: "New users over 5 months",
    value: "new_users_over_last_5_months",
    key: "new_users_count",
    type: "user",
  },
  {
    title: "Quiz attempts over 5 days",
    value: "quiz_attempts_over_last_5_days",
    key: "quiz_attempts_count",
    type: "quiz",
  },
  {
    title: "Quiz attempts over 5 months",
    value: "quiz_attempts_over_last_5_months",
    key: "quiz_attempts_count",
    type: "quiz",
  },
  {
    title: "Quiz attempts over 5 weeks",
    value: "quiz_attempts_over_last_5_weeks",
    key: "quiz_attempts_count",
    type: "quiz",
  },
  {
    title: "Feature doc summary over 5 days",
    value: "feat_doc_summary_over_last_5_days",
    key: "count",
    type: "summary",
  },
  {
    title: "Feature doc summary over 5 weeks",
    value: "feat_doc_summary_over_last_5_weeks",
    key: "count",
    type: "summary",
  },
  {
    title: "Feature doc summary over 5 months",
    value: "feat_doc_summary_over_last_5_months",
    key: "count",
    type: "summary",
  },
  {
    title: "Feature doc explain over 5 days",
    value: "feat_doc_explain_over_last_5_days",
    key: "count",
    type: "explain",
  },
  {
    title: "Feature doc explain over 5 weeks",
    value: "feat_doc_explain_over_last_5_weeks",
    key: "count",
    type: "explain",
  },
  {
    title: "Feature doc explain over 5 months",
    value: "feat_doc_explain_over_last_5_months",
    key: "count",
    type: "explain",
  },
  {
    title: "Feature doc quiz over 5 days",
    value: "feat_doc_quiz_over_last_5_days",
    key: "count",
    type: "feature_quiz",
  },
  {
    title: "Feature doc quiz over 5 weeks",
    value: "feat_doc_quiz_over_last_5_weeks",
    key: "count",
    type: "feature_quiz",
  },
  {
    title: "Feature doc quiz over 5 months",
    value: "feat_doc_quiz_over_last_5_months",
    key: "count",
    type: "feature_quiz",
  },
];

const Insights = () => {
  const { access_token } = useAuth();
  const [currentInsight, setCurrentInsight] = useState({});
  const [loading, setLoading] = useState(false);
  const [Error, setError] = useState("");
  const [chartData, setChartData] = useState({ series: [], options: {} });
  useEffect(() => {
    if (data.length > 0) {
      setCurrentInsight(data[0]);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (data.length > 0) {
        try {
          if (access_token) {
            setLoading(true);
            const res = await API.getInsights(
              access_token,
              currentInsight.value
            );

            const dates = res.data.map((item) => item.date);
            const dynamicData = res.data.map(
              (item) => item[currentInsight.key]
            );

            const newData = {
              series: [
                {
                  name: currentInsight.key,
                  data: dynamicData,
                },
              ],
              options: {
                chart: {
                  height: 600,
                  type: "area",
                  zoom: {
                    type: "x",
                    enabled: true,
                    autoScaleYaxis: true,
                  },
                  dropShadow: {
                    enabled: true,
                    color: "#000",
                    top: 18,
                    left: 7,
                    blur: 10,
                    opacity: 0.2,
                  },
                },
                dataLabels: {
                  enabled: true,
                },
                stroke: {
                  curve: "smooth",
                },
                title: {
                  text: currentInsight.title,
                  align: "left",
                },
                grid: {
                  row: {
                    colors: ["#f3f3f3", "transparent"],
                    opacity: 0.5,
                  },
                },
                xaxis: {
                  categories: dates,
                  title: {
                    text: "Date",
                  },
                },
                yaxis: {
                  title: {
                    text: currentInsight.key,
                  },
                },
              },
            };

            setChartData(newData);
            setLoading(false);
          }
        } catch (error) {
          setLoading(false);
          setError(error);
          if (error.message === "No analytics data found") {
            toast.error("Failed to get the Insight");
          }
          console.error("Error in getting Insights.", error);
        }
      }
    };

    fetchData();
  }, [
    access_token,
    currentInsight.value,
    currentInsight.key,
    currentInsight.title,
    currentInsight.buttonColor,
  ]);

  const handleSelect = (item) => {
    setCurrentInsight(item);
  };

  return (
    <>
      {Error.length > 0 ? (
        <div>
          <p>sorry an error occured : {Error}</p>
        </div>
      ) : (
        <div className="flex flex-col gap-3 mt-[4rem] sm:mt-[0rem]  sm:flex-row w-full">
          <div className="w-full m-4 sm:w-1/3">
            <ScrollShadow size={30} className="h-[50vh] sm:h-[80vh]">
              {/* <div className="flex m-4 flex-col gap-2"> */}

              <Accordion defaultExpandedKeys={["1"]} variant="splitted">
                {/* PDF ANALYSIS */}
                <AccordionItem
                  key="1"
                  aria-label="Accordion 1"
                  title="PDF ANALYSIS"
                >
                  <Accordion defaultExpandedKeys={["1"]} variant="splitted">
                    <AccordionItem
                      key="1"
                      aria-label="Accordion 1"
                      title="Users"
                    >
                      {data
                        .filter((item) => item.key === "new_users_count")
                        .map((item, index) => (
                          <div key={index} className="flex m-2 flex-col">
                            <Button
                              style={{
                                backgroundColor: "#add8e6",
                                border:
                                  item.value === currentInsight.value
                                    ? "2px solid darkblue"
                                    : "",
                              }}
                              onClick={() => handleSelect(item)}
                              className="mt-2 sm:mt-0"
                            >
                              {item.title}
                            </Button>
                          </div>
                        ))}
                    </AccordionItem>
                    <AccordionItem
                      key="2"
                      aria-label="Accordion 2"
                      title="Quizes"
                    >
                      {data
                        .filter((item) => item.key === "quiz_attempts_count")
                        .map((item, index) => (
                          <div key={index} className="flex m-2 flex-col">
                            <Button
                              style={{
                                backgroundColor: "#add8e6",
                                border:
                                  item.value === currentInsight.value
                                    ? "2px solid darkblue"
                                    : "",
                              }}
                              onClick={() => handleSelect(item)}
                              className="mt-2 sm:mt-0" // Add margin top of 2rem for mobile devices
                            >
                              {item.title}
                            </Button>
                          </div>
                        ))}
                    </AccordionItem>
                  </Accordion>
                </AccordionItem>

                {/* DOCUMENT ANALYSIS */}
                {/* <AccordionItem
                key="2"
                aria-label="Accordion 2"
                title="DOCUMENT ANALYSIS"
              >
                <Accordion defaultExpandedKeys={["1"]} variant="splitted">
                  <AccordionItem
                    key="1"
                    aria-label="Accordion 3"
                    title="Explanations"
                  >
                    {data
                      .filter((item) => item.type === "explain")
                      .map((item, index) => (
                        <div key={index} className="flex m-2 flex-col">
                          <Button
                            style={{
                              backgroundColor: "#add8e6",
                              border:
                                item.value === currentInsight.value
                                  ? "2px solid darkblue"
                                  : "",
                            }}
                            onClick={() => handleSelect(item)}
                            className="mt-2 sm:mt-0" // Add margin top of 2rem for mobile devices
                          >
                            {item.title}
                          </Button>
                        </div>
                      ))}
                  </AccordionItem>
                  <AccordionItem
                    key="2"
                    aria-label="Accordion 3"
                    title="Summary"
                  >
                    {data
                      .filter((item) => item.type === "summary")
                      .map((item, index) => (
                        <div key={index} className="flex m-2 flex-col">
                          <Button
                            style={{
                              backgroundColor: "#add8e6",
                              border:
                                item.value === currentInsight.value
                                  ? "2px solid darkblue"
                                  : "",
                            }}
                            onClick={() => handleSelect(item)}
                            className="mt-2 sm:mt-0" // Add margin top of 2rem for mobile devices
                          >
                            {item.title}
                          </Button>
                        </div>
                      ))}
                  </AccordionItem>
                  <AccordionItem key="3" aria-label="Accordion 3" title="Quiz">
                    {data
                      .filter((item) => item.type === "feature_quiz")
                      .map((item, index) => (
                        <div key={index} className="flex m-2 flex-col">
                          <Button
                            style={{
                              backgroundColor: "#add8e6",
                              border:
                                item.value === currentInsight.value
                                  ? "2px solid darkblue"
                                  : "",
                            }}
                            onClick={() => handleSelect(item)}
                            className="mt-2 sm:mt-0" // Add margin top of 2rem for mobile devices
                          >
                            {item.title}
                          </Button>
                        </div>
                      ))}
                  </AccordionItem>
                </Accordion>
              </AccordionItem> */}
              </Accordion>
            </ScrollShadow>
          </div>
          <div className="w-full sm:w-2/3">
            {!loading ? (
              <div className="m-5">
                <ReactApexChart
                  options={chartData.options}
                  series={chartData.series}
                  type="line"
                  height={350}
                />
              </div>
            ) : (
              <div className="flex items-center justify-center h-full mx-auto">
                <CircularProgress label="Loading Graph..." size="lg" />
              </div>
            )}
          </div>
        </div>
      )}
      <Toaster />
    </>
  );
};

export default Insights;
