import React from "react";
import { ReportCard, ReportText } from "../../Global.styled";
import { ReportCardsContainer } from "./index.styled";
const UserStatCards = ({ engagementsData }) => {
  return (
    <div>
      <div style={{ marginTop: "10px" }}>
        <div className="text-center font-semibold mb-4 mt-4 text-xl">
          Overall Performance
        </div>
        <ReportCardsContainer>
          <ReportCard>
            <ReportText>{engagementsData.crct_ans_count}</ReportText>
            <span>Correct Answers</span>
          </ReportCard>
          <ReportCard>
            <ReportText>{engagementsData.crct_ans_percent} %</ReportText>
            <span>Corrects' Percentage</span>
          </ReportCard>
          <ReportCard>
            <ReportText>{engagementsData.incrct_ans_count}</ReportText>
            <span>Incorrect Answers</span>
          </ReportCard>
          <ReportCard>
            <ReportText>{engagementsData.mins_spent}</ReportText>
            <span>Total mins spent</span>
          </ReportCard>
          <ReportCard>
            <ReportText>{engagementsData.total_questions_attempted}</ReportText>
            <span>Attempted Questions</span>
          </ReportCard>
          <ReportCard>
            <ReportText>{engagementsData.total_quizzes_attempted}</ReportText>
            <span>Attempted Quizzes</span>
          </ReportCard>
          <ReportCard>
            <ReportText>
              {engagementsData.free_credits_used === null
                ? 0
                : engagementsData.free_credits_used}
            </ReportText>
            <span>Free Credits Used</span>
          </ReportCard>
        </ReportCardsContainer>
      </div>
      <div></div>
    </div>
  );
};

export default UserStatCards;

// How much time they spent on each PDF
// - How many quizzes they took related to each PDF
// - How many questions they tried
// - How many answers they got right or wrong
// - Their percentage of correct answers for each PDF
// - How many free credits they used on each PDF
