import React, { useState, useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormHelperText,
  TextField,
  Button,
} from "@mui/material";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { useAuth } from "../../Auth/Auth";
import API from "../../API";
import { FaArrowDown, FaArrowUp, FaSearch } from "react-icons/fa";
import { Toaster, toast } from "react-hot-toast";
import SaudiTime from "./SaudiTime";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { CircularProgress } from "@nextui-org/react";

export default function UserDataTable() {
  const [usersData, setUsersData] = useState([]); //api data setting
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    const savedRowsPerPage = localStorage.getItem("rowsPerPage");
    return savedRowsPerPage !== null ? JSON.parse(savedRowsPerPage) : 10;
  }); //no. of users to fetch (or) no. of rows to fetch and show, initially get it from localstorage (to persist) or 10
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPageNo = localStorage.getItem("currentPageNo");
    return savedPageNo !== null ? JSON.parse(savedPageNo) : 1; //pages also set to locastorage or 1
  });
  const [sortItem, setSortItem] = useState({ field: "", order: "" }); //to specify sort by what param
  const [searchParam, setSearchParam] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchedDateValue, setSearchedDateValue] = useState(null);
  const [fetchedUsers, setFetchedUsers] = useState(null);
  const [searchCount, setSearchCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const { access_token, loggedIn } = useAuth();

  useEffect(() => {
    const fetchAllUsers = async () => {
      if (access_token) {
        try {
          setLoading(true);
          let sort = sortItem.field ? sortItem : undefined;
          let search;

          if (searchParam === "first_attempt_date") {
            // When the searchParam is 'first_attempt_date', format the date using dayjs
            search = {
              [searchParam]: dayjs(searchedDateValue).format("DD-MM-YYYY"),
            };
          } else if (searchParam && searchValue) {
            // For other searchParams, use the searchValue as it is
            search = {
              [searchParam]: searchValue,
            };
          } else {
            // If no searchParam or searchValue is provided, search is undefined
            search = undefined;
          }
          const res = await API.getAllUsers(
            access_token,
            Number(currentPage),
            Number(rowsPerPage),
            sort,
            search,
            fetchedUsers
          );
          setUsersData(res.users);
          setLoading(false);
          setFetchedUsers(res.count);
        } catch (error) {
          setLoading(false);
          console.log("Error in fetching users", error);
        }
      }
    };

    fetchAllUsers();
  }, [access_token, loggedIn, currentPage, rowsPerPage, sortItem, searchCount]);

  useEffect(() => {
    localStorage.setItem("rowsPerPage", JSON.stringify(rowsPerPage));
    localStorage.setItem("currentPageNo", JSON.stringify(currentPage));
  }, [rowsPerPage, currentPage]);

  const handleChangeRows = (event) => {
    setRowsPerPage(event.target.value);
    setCurrentPage(1);
  };

  const handlePageIncrement = (page) => {
    setCurrentPage((curr) => curr + 1);
  };
  const handlePageDecrement = (page) => {
    setCurrentPage((curr) => curr - 1);
  };

  const handleSearch = () => {
    if (searchedDateValue) {
      setSearchParam("first_attempt_date");
      setSearchValue(searchedDateValue);
      setSearchCount((prev) => prev + 1);
      setCurrentPage(1);
      console.log("I am searching on Date", searchParam, searchValue);
    }
    if (searchParam !== "" && searchValue !== "") {
      if (searchValue.length < 3) {
        toast.error("Please enter at least 3 characters to search.");
      } else {
        setSearchParam(searchParam);
        setSearchValue(searchValue);
        setSearchCount((prev) => prev + 1);
        setCurrentPage(1);
      }
    }
  };

  const handleSort = (fieldId) => {
    setCurrentPage(1);
    setSortItem((prev) => {
      if (prev.field !== fieldId) {
        return { field: fieldId, order: "asc" };
      } else if (prev.order === "asc") {
        return { field: fieldId, order: "desc" };
      } else {
        return { field: undefined, order: undefined };
      }
    });
  };
  const handleExportUserData = async () => {
    console.log("=======user data===========", usersData);
    const format = "excel"; // this can be a state variable if it needs to change
    let search;
    if (searchParam === "first_attempt_date") {
      // When the searchParam is 'first_attempt_date', format the date using dayjs
      search = {
        [searchParam]: dayjs(searchedDateValue).format("DD-MM-YYYY"),
      };
    } else if (searchParam && searchValue) {
      // For other searchParams, use the searchValue as it is
      search = {
        [searchParam]: searchValue,
      };
    } else {
      // If no searchParam or searchValue is provided, search is undefined
      search = undefined;
    }
    const sort = sortItem.field && sortItem.order ? sortItem : undefined;

    try {
      setLoading(true);
      await API.exportUserData(access_token, format, search, sort);
      toast.success("Table Data Exported.");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("export failed!");
      console.error("Error: failed to export user data.", error);
    }
  };

  const columns = [
    { id: "id", label: "ID" },
    { id: "username", label: "Username" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" },
    { id: "platform_id", label: "Platform ID" },
    { id: "total_minutes_spent", label: "Time Spent" },
    { id: "first_attempt_date", label: "First Engagement" },
  ];

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {loading ? (
        <div className="text-2xl h-full w-full absolute top-0 z-[10000]">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(3px)",
              zIndex: 9999, // adjust this value as needed
            }}
          >
            {loading && <CircularProgress aria-label="Loading..." size="lg" />}
          </div>
        </div>
      ) : (
        ""
      )}
      {/* searching start */}
      <form
        onSubmit={(e) => {
          e.preventDefault(); // Prevent the page from refreshing
          handleSearch(); // Call your search function
        }}
      >
        <div>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <div className="flex gap-3">
              <InputLabel id="demo-simple-select-helper-label">
                Search Field
              </InputLabel>
              <Select
                required
                sx={{ minWidth: "150px" }}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={searchParam}
                label="Search"
                onChange={(e) => setSearchParam(e.target.value)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={`username`}>Username</MenuItem>
                <MenuItem value={`email`}>Email</MenuItem>
                <MenuItem value={`phone`}>Phone</MenuItem>
                <MenuItem value={`platform_id`}>PLatform Id</MenuItem>
                <MenuItem value={`first_attempt_date`}>
                  First Engagement
                </MenuItem>
              </Select>
              {searchParam === "first_attempt_date" ? (
                <div>
                  <DatePicker
                    // value={searchedDateValue ? searchValue : null}
                    onChange={(newValue) => {
                      setSearchedDateValue(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              ) : (
                <TextField
                  required
                  id="outlined-basic"
                  label="Search Value"
                  variant="outlined"
                  value={searchValue}
                  onChange={(e) => setSearchValue(e.target.value)}
                />
              )}
              <div>
                <div className="">
                  <div className="bg-[darkblue] p-[10px] flex items-center rounded-md">
                    <IconButton type="submit" onClick={handleSearch}>
                      <FaSearch style={{ color: "white" }} size={20} />
                    </IconButton>
                  </div>
                </div>
              </div>
              <Button
                onClick={() => {
                  setSearchCount(0);
                  setSearchParam("");
                  setSearchValue("");
                  setCurrentPage(1);
                }}
                sx={{
                  marginLeft: "20px",
                  color: "darkblue",
                  border: "1px solid darkblue",
                }}
                variant="outlined"
              >
                Clear
              </Button>
            </div>
            <FormHelperText>Search with the above params</FormHelperText>
          </FormControl>
        </div>
      </form>
      {/* searching end */}
      <div className="flex justify-between items-center">
        <p className="text-lg font-semibold ml-3 mb-3">
          {`${fetchedUsers} ${fetchedUsers > 1 ? "users" : "user"}`}
        </p>
        <div className="m-5">
          <Button
            onClick={handleExportUserData}
            variant="outlined"
            color="primary"
          >
            Export Data
          </Button>
        </div>
      </div>

      <Paper>
        <TableContainer sx={{ maxHeight: 570 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => (
                  <Tooltip
                    key={index}
                    title={`Sort by ${column.label}`}
                    enterDelay={700}
                    placement="top-start"
                  >
                    <TableCell
                      onClick={() => {
                        if (searchParam === "" || searchValue === "") {
                          setSearchParam("");
                          setSearchValue("");
                        }
                        handleSort(column.id);
                      }}
                      sx={{
                        userSelect: "none",
                        backgroundColor: "darkblue",
                        color: "white",
                        cursor: "pointer",
                      }}
                    >
                      <span className="flex transition-transform items-center gap-3 font-semibold text-lg">
                        {sortItem.field === column.id &&
                          (sortItem.order === "asc" ? (
                            <FaArrowDown />
                          ) : sortItem.order === "desc" ? (
                            <FaArrowUp />
                          ) : (
                            ""
                          ))}
                        {column.label}
                      </span>
                    </TableCell>
                  </Tooltip>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {usersData.length > 0 ? (
                usersData.map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    onClick={() => {
                      const obj = {
                        id: row?.id,
                        p_id: row?.platform_id,
                        name: row?.username,
                        email: row?.email,
                        phone: row?.phone,
                        time: row?.total_minutes_spent,
                        first_engagement: row?.first_attempt_date,
                        non_attempter: false,
                      };
                      localStorage.setItem(
                        "current_user",
                        JSON.stringify({ current_user: obj })
                      );
                      window.location.href = `/${row.id}`;
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {columns.map((column, index) => (
                      <TableCell key={index}>
                        {column.id === "first_attempt_date" ? (
                          // Use the SaudiTime component for the first_attempt_date field
                          <SaudiTime dateString={row[column.id]} icon={false} />
                        ) : // For other fields, just display the value
                        row[column.id] !== undefined &&
                          row[column.id] !== null ? (
                          row[column.id]
                        ) : (
                          "No data"
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} align="center">
                    No data found!
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="flex items-center px-2 bg-slate-100 select-none justify-end m-3 gap-4 mb-4">
          <FormControl variant="standard" sx={{ m: 1, minWidth: 100 }}>
            <InputLabel id="demo-simple-select-standard">
              Rows per page
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={rowsPerPage}
              label="Rows per page"
              onChange={handleChangeRows}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
              <MenuItem value={500}>500</MenuItem>
            </Select>
          </FormControl>
          <Tooltip
            title={`${
              currentPage < 2 ? "Cannot move back further" : "Previous Page"
            }`}
          >
            <span
              style={{ cursor: currentPage < 2 ? "not-allowed" : "pointer" }}
            >
              <IconButton
                disabled={currentPage < 2 ? true : false}
                onClick={handlePageDecrement}
              >
                <GrFormPrevious
                  style={{ color: currentPage < 2 ? "gray" : "default" }}
                />
              </IconButton>
            </span>
          </Tooltip>
          <p className="font-bold text-lg">
            {" "}
            {`${currentPage} of ${Math.ceil(fetchedUsers / rowsPerPage)}`}
          </p>
          <Tooltip
            title={`${
              Math.ceil(fetchedUsers / rowsPerPage) === currentPage
                ? `Cannot move further`
                : "Next Page"
            }`}
          >
            <span
              style={{
                cursor:
                  Math.ceil(fetchedUsers / rowsPerPage) === currentPage
                    ? "not-allowed"
                    : "pointer",
              }}
            >
              <IconButton
                disabled={Math.ceil(fetchedUsers / rowsPerPage) === currentPage}
                onClick={handlePageIncrement}
              >
                <GrFormNext />
              </IconButton>
            </span>
          </Tooltip>
        </div>
        <Toaster />
      </Paper>
    </LocalizationProvider>
  );
}
