import axios from "axios";
import { toast } from "react-hot-toast";
import qs from "qs";
import ServerError from "../pages/ServerError";
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "69420",
  },
  timeout: 20000, //waiting time before requesting the server.
});

apiClient.interceptors.response.use(
  (response) => {
    // If the response was successful, just return it
    return response;
  },
  (error) => {
    if (error.response) {
      const status = error.response.status;
      if (status === 401) {
        localStorage.removeItem("access_token");
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">Session expired. Please login.</div>
            <div className="flex border-l border-gray-200"></div>
          </div>
        ));
        window.location = "/login";
      } else if (status === 404) {
        console.log("No data found!");
      } else if (status === 403) {
        window.location = "/login";
        alert("logged out 500");
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">Unexpectd error occured</div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => {
                  window.location = "/login";
                }}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Retry
              </button>
            </div>
          </div>
        ));
      } else if (status >= 500 && status < 600) {
        <div>
          <ServerError />
        </div>;
      }
    } else if (error.message) {
      toast(`${error.message} \n\n ${error.code} : ${error.name}`);
    } else {
      toast(
        "Network error \n\n Something went wrong with network connection.",
        {
          duration: 6000,
        }
      );
    }

    return Promise.reject(error);
  }
);

// const { get } = apiClient;

export const API = {
  login: async (email, password) => {
    try {
      const response = await apiClient.post("/admin/login", {
        email: email,
        password: password,
      });
      const data = response.data;
      if (data.access_token) {
        localStorage.setItem(
          "access_token",
          JSON.stringify({ access_token: data.access_token })
        );
        return data.access_token;
      } else {
        return null;
      }
    } catch (error) {
      // toast.error("An error occurred while trying to log in.");
      console.error("Error in login:", error);
      return null;
    }
  },
  getAllUsers: async (token, page, limit, sortItem, searchQuery) => {
    try {
      const response = await apiClient.get(
        `/admin/users?page=${page}&limit=${
          limit
          // fetchedUsers ? fetchedUsers : limit
        }${
          sortItem
            ? `&sort=${encodeURIComponent(
                JSON.stringify({ [sortItem.field]: sortItem.order })
              )}`
            : ""
        }${
          searchQuery
            ? `&search=${encodeURIComponent(JSON.stringify(searchQuery))}`
            : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "ngrok-skip-browser-warning": "69420",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        return response.data;
      } else {
        toast.error("Failed to fetch users. Try again");
      }
    } catch (error) {
      console.error("Error in fetching users:", error);
      if (error.response && error.response.status === 404) {
        toast.error("No data found! It's 404");
      } else {
        console.log("Failed to get the users, Try again");
      }
      throw error;
    }
  },
  getSingleUser: async (id, token) => {
    try {
      const response = await apiClient.get(`/admin/engagements/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error in fetching user data:", error);
      if (error.response && error.response.status === 404) {
        toast(
          `User with the ID ${id} is not found. `,
          {
            icon: "😬",
            style: {
              borderRadius: "30px",
              background: "#333",
              color: "#fff",
            },
          },
          { duration: 8000 }
        );
      } else {
        toast.error("Failed to get the user, Try again");
      }
      throw error;
    }
  },
  getPdfData: async (userId, pdf_uuid, token) => {
    try {
      const response = await apiClient.get(
        `/admin/engagements/${userId}/${pdf_uuid}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error in geting single pdf engagement data:", error);
      toast.error("Failed to get the engagement detail, Try again");
      throw error;
    }
  },
  getEngagementSummary: async (token) => {
    try {
      const response = await apiClient.get("/admin/engagements/", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error: failed to get the engagement summary data.", error);
      throw error;
    }
  },
  getPerformancedata: async (token, user_id, category_id) => {
    try {
      const response = await apiClient.get(
        `/admin/user-performance/${user_id}/${category_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error: failed to get perforamance data.", error);
    }
  },
  getCategories: async (token) => {
    try {
      const response = await apiClient.get("/admin/pdf-categories", {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error: failed to get categories data.", error);
    }
  },
  editFile: async (token, pdf_id, name) => {
    try {
      const response = await apiClient.put(
        `/admin/pdf-file/${pdf_id}?new_name=${name}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.status && error.message) {
        if (error.status === 422) {
          alert("Invalid PDF UUID or Please give a PDF to Edit.");
        }
        if (error.status === 404) {
          alert("No pdf file found");
        }
      }
      console.error("Error: failed to rename pdf file.", error);
    }
  },
  changePdfStatus: async (token, pdf_id, newStatus) => {
    try {
      const response = await apiClient.put(
        `/admin/pdf-file/${pdf_id}?status=${newStatus}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.status && error.message) {
        if (error.status === 422) {
          alert(
            "Invalid PDF UUID or Please give a PDF to change the status of the pdf file."
          );
        }
        if (error.status === 404) {
          alert("No pdf file found");
        }
      }
      console.error("Error: failed to change the status of pdf file.", error);
    }
  },
  editCategory: async (token, category_id, name, desc, editType, status) => {
    try {
      let endpoint;
      if (editType === "status") {
        endpoint = `/admin/pdf-category/${category_id}?status=${status}`;
      } else if (editType === "description") {
        endpoint = `/admin/pdf-category/${category_id}?description=${desc}`;
      } else {
        endpoint = `/admin/pdf-category/${category_id}?new_name=${name}`;
      }

      const response = await apiClient.put(
        endpoint,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      if (error.status && error.message) {
        if (error.status === 422) {
          alert("Please give a PDF to Edit.");
        }
        if (error.status === 404) {
          alert("No pdf category found");
        }
      }
      alert("error in renaming category");
      console.error("Error: failed to rename pdf category.", error);
    }
  },
  deleteFile: async (token, pdf_id) => {
    try {
      const response = await apiClient.delete(`/admin/pdf-file/${pdf_id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (error) {
      console.error("Error: failed to delete PDF file.", error);
    }
  },
  createNewCategory: async (token, catName, catDescription) => {
    try {
      const response = await apiClient.post(
        "/admin/pdf-category",
        {
          name: catName,
          description: catDescription,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      toast.error("!Failed to create Category");
      console.error("Error in createNewCategory:", error);
      return null;
    }
  },
  addFile: async (token, catId, PdfName, FileURL) => {
    try {
      const response = await apiClient.post(
        "/admin/add-pdf-file",
        {
          category_id: catId,
          pdf_name: PdfName,
          file_url: FileURL,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      toast.error("Failed to upload the File");
      console.error("Error in adding the uploaded file:", error);
      return null;
    }
  },
  exportUserData: async (token, format, search, sort) => {
    try {
      const queryString = qs.stringify(
        {
          sort: sort ? JSON.stringify({ [sort.field]: sort.order }) : undefined,
          search: search ? JSON.stringify(search) : undefined,
          format: format,
        },
        { skipNulls: true, indices: false }
      );
      console.log(queryString);
      const response = await apiClient.get(
        `/admin/export-users?${queryString}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        return response.data;
      } else if (response.status === 422) {
        throw new Error("Invalid format, search, or sort data");
      } else if (response.status === 500) {
        throw new Error("Internal Server Error: No pdf category found");
      } else if (response.status === 401) {
        throw new Error("Unauthorized: Token expired/Bad token provided");
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error: failed to export user data.", error);
      throw error;
    }
  },
  getInsights: async (token, analytics_type) => {
    try {
      if (analytics_type !== undefined) {
        const response = await apiClient.get(
          `/admin/analytics/${analytics_type}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        return response.data;
      }
    } catch (error) {
      console.error("Error: failed to get insigts.", error);
    }
  },
  getNonAttmepters: async (token, page, limit, sortItem, searchQuery) => {
    try {
      const response = await apiClient.get(
        `/admin/users/non_attempters?page=${page}&limit=${limit}${
          sortItem
            ? `&sort=${encodeURIComponent(
                JSON.stringify({ [sortItem.field]: sortItem.order })
              )}`
            : ""
        }${
          searchQuery
            ? `&search=${encodeURIComponent(JSON.stringify(searchQuery))}`
            : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/json",
            "ngrok-skip-browser-warning": "69420",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data) {
        return response.data;
      } else {
        toast.error("Failed to fetch users. Try again");
      }
    } catch (error) {
      console.error("Error in fetching users:", error);
      if (error.response && error.response.status === 404) {
        toast.error("No data found! It's 404");
      } else {
        console.log("Failed to get the users, Try again");
      }
      throw error;
    }
  },
};

export default API;
