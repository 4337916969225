import styled from "styled-components";
export const CatMainDiv = styled.div`
  margin: 20px;
  /* border: 1px solid red; */
  @media (max-width: 720px) {
    /* border: 1px solid red; */
  }
`;
export const CategoryBoxContainer = styled.div`
  box-sizing: border-box;
  background: #f7f7f7;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border-radius: 7px;
  text-align: center;
  transition: all 0.4s;
  display: flex;
  flex-direction: column;
  align-items: start;
  /* width: 350px;
  max-height: 300px; */
  overflow-x: auto;
  padding: 12px;
  margin: 10px;

  &:hover {
    border: 1px solid grey;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
    transform: scale(1.005);
  }

  @media (max-width: 600px) {
    min-width: 100%;
  }
`;
export const PDFButton = styled.button`
  width: 100%;
  padding: 5px;
  border-radius: 7px;
  font-size: large;
  letter-spacing: 1.5px;
  transition: all 0.3s ease;
  border: 1px solid lightgray;
  &:hover {
    background-color: #e2ebeb;
    transition: all ease 0.3s;
  }
`;
export const CategoriesContainerDiv = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.activeMenu ? "repeat(4, 1fr)" : "repeat(5, 1fr)"};
  @media (max-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
