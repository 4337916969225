import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  CircularProgress,
} from "@nextui-org/react";
import { TbFileDescription } from "react-icons/tb";
import { CgNametag } from "react-icons/cg";
import { useState } from "react";
import { useAuth } from "../../Auth/Auth";
import API from "../../API";
import { Toaster, toast } from "react-hot-toast";
import { useStateContext } from "../../contexts/ContextProvider";
import { IoClose } from "react-icons/io5";

const NewCatCreate = ({ isCreateModalOpen, setisCreateModalOpen }) => {
  const { access_token } = useAuth();
  const { refresh, setRefresh } = useStateContext();
  const { onOpenChange } = useDisclosure();

  const [catName, setCatName] = useState("");
  const [catDescription, setCatDescription] = useState("");
  const [creating, setCreating] = useState(false);

  const handleCloseModal = (onClose) => {
    setisCreateModalOpen(false);
    onClose();
  };

  const handleDone = async (onClose) => {
    if (catName.length > 0 && catDescription.length > 0) {
      try {
        if (access_token) {
          setCreating(true);
          const res = await API.createNewCategory(
            access_token,
            catName,
            catDescription
          );
          if (res.status === 200) {
            toast.success("Category Created");
          }
          toast.success("Category Created");
          setCreating(false);
        }
      } catch (error) {
        setCreating(false);
        alert("cannot create new category");
        console.log("error in creating category ", error);
      }

      setRefresh(refresh + 1);
      setisCreateModalOpen(false);
      onClose();
    } else {
      toast.error("Type something to rename.", {
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "red",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
  };
  return (
    <div>
      {creating ? (
        <div className="flex h-[80vh] justify-center items-center">
          <div className="flex justify-center shadow-md items-center rounded-md  flex-col gap-1 p-2 border">
            <span>
              <CircularProgress size="lg" label={"Creating..."} />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      <>
        <Modal
          hideCloseButton
          backdrop="opaque"
          isOpen={isCreateModalOpen}
          onOpenChange={onOpenChange}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  Creating New Category
                </ModalHeader>
                <ModalBody>
                  <div className="absolute top-0 right-0 m-1">
                    <Button
                      onPress={() => handleCloseModal(onClose)}
                      variant="light"
                      isIconOnly
                    >
                      <IoClose size={25} />
                    </Button>
                  </div>
                  <Input
                    value={catName}
                    onChange={(e) => setCatName(e.target.value)}
                    autoFocus
                    endContent={
                      <CgNametag className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                    }
                    label="Create Category"
                    placeholder="Enter the Name"
                    variant="bordered"
                  />
                  <Input
                    value={catDescription}
                    onChange={(e) => setCatDescription(e.target.value)}
                    endContent={
                      <TbFileDescription className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                    }
                    label="Decription"
                    placeholder="Description about the Category"
                    type="text"
                    variant="bordered"
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="default"
                    variant="flat"
                    onPress={() => handleCloseModal(onClose)}
                  >
                    Close
                  </Button>
                  <Button color="primary" onPress={() => handleDone(onClose)}>
                    Create
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
          <Toaster />
        </Modal>
      </>
    </div>
  );
};

export default NewCatCreate;
