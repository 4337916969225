import React from "react";
import { useAuth } from "../../Auth/Auth";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  Button,
  CircularProgress,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import { useState } from "react";
import API from "../../API";
import { Toaster, toast } from "react-hot-toast";
import { IoClose } from "react-icons/io5";

const MakeActive = ({
  isOpen,
  setisOpen,
  isActiveCategory,
  setIsActiveCategory,
  category,
}) => {
  const { access_token } = useAuth();
  const { refresh, setRefresh } = useStateContext();
  const { onOpenChange } = useDisclosure();

  const [msg, setMsg] = useState("");

  const handleCloseModal = (onClose) => {
    setisOpen(false);
    onClose();
  };

  const handleDone = async (onClose) => {
    try {
      if (access_token) {
        setMsg(`Making ${isActiveCategory ? " active" : " inactive"}`);
        await API.editCategory(
          access_token,
          category.id,
          "",
          "",
          "status",
          !isActiveCategory
        );
      }
    } catch (error) {
      setMsg("");
      toast.error("Editing category failed!");
      console.log("error in editing category Status", error);
    }

    setRefresh(refresh + 1);
    setisOpen(false);
    onClose();
  };
  return (
    <div>
      {msg.length > 0 ? (
        <div className="flex h-[80vh] justify-center items-center">
          <div className="flex justify-center shadow-md items-center rounded-md  flex-col gap-1 p-2 border">
            <span>
              <CircularProgress size="lg" label={"Loading..."} />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      <>
        <Modal
          hideCloseButton
          backdrop="blur"
          isOpen={isOpen}
          onOpenChange={onOpenChange}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  <div className="flex justify-start items-start flex-col">
                    <span>Making {category?.name} </span>
                    <span className="text-sm font-normal">
                      Making category{" "}
                      {isActiveCategory ? " in active" : " active"}...
                    </span>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div className="absolute top-0 right-0 m-1">
                    <Button
                      onPress={() => handleCloseModal(onClose)}
                      variant="light"
                      isIconOnly
                    >
                      <IoClose size={25} />
                    </Button>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="default"
                    variant="flat"
                    onPress={() => handleCloseModal(onClose)}
                  >
                    Close
                  </Button>
                  <Button color="primary" onPress={() => handleDone(onClose)}>
                    Done
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
          <Toaster />
        </Modal>
      </>
    </div>
  );
};

export default MakeActive;
