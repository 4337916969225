import { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { useAuth } from "../../Auth/Auth";
import {
  BlueborderBoxContainer,
  CatsPDFsBarchartContainer,
} from "./index.styled";
import API from "../../API";
import { useParams } from "react-router-dom";
import { CircularProgress } from "@nextui-org/react";

const CatsPDFsBarchart = ({ activePdf, activeCategory }) => {
  const { access_token } = useAuth();
  const { id } = useParams();

  const [Loading, setLoading] = useState(false);
  const [performanceData, setPerformanceData] = useState([]);

  useEffect(() => {
    const fetchPerformanceData = async () => {
      if (access_token) {
        try {
          setLoading(true);
          const res = await API.getPerformancedata(
            access_token,
            id,
            activeCategory?.CatId
          );

          if (Array.isArray(res?.performance)) {
            setPerformanceData(res.performance);
          } else {
            console.error("Error: performance data is not an array");
          }
          setLoading(false);
          console.log("performances", performanceData);
        } catch (error) {
          setLoading(false);
          console.error("Error in getting performance data ", error);
        }
      }
    };
    fetchPerformanceData();
  }, [access_token, id, activeCategory]);

  const xAxisData = performanceData.map((item) => item?.pdf_name);
  const seriesData = performanceData.map(
    (item) => (item?.correct_questions / item?.total_questions) * 100
  );
  return (
    <div>
      {!Loading ? (
        <CatsPDFsBarchartContainer>
          <BlueborderBoxContainer>
            <div>
              <p className="text-center text-lg font-semibold m-3">
                <span>
                  {activeCategory?.CatName} <br /> Overall performances
                </span>
                <span className="text-xs text-gray-600">
                  &nbsp;&#40;out of 100% &#41;
                </span>
              </p>
            </div>
            {performanceData.length > 0 ? (
              <BarChart
                xAxis={[{ scaleType: "band", data: xAxisData }]}
                series={[{ data: seriesData }]}
                width={600}
                height={350}
              />
            ) : null}
          </BlueborderBoxContainer>
        </CatsPDFsBarchartContainer>
      ) : (
        <div className="mb-4 p-3 rounded-md shadow-md">
          <CircularProgress label="Loading Graph..." />
        </div>
      )}
    </div>
  );
};

export default CatsPDFsBarchart;
