import React from "react";
import { MainComponentContainer } from "../../Global.styled";
import { useNavigate } from "react-router-dom";
import Unauthorized from "../../pages/UnauthorizedPage";
import UserDataTable from "./UserDataTable";
import { UserDataTableContainer } from "../Dashboard/index.styled";
import { useAuth } from "../../Auth/Auth";

const NonAttempters = () => {
    const navigate = useNavigate();
    const { loggedIn, access_token } = useAuth();

    // useEffect(() => {
    //NO useEffect- leads to unusual redirection because of async to '/login' often.
    if (!loggedIn && !access_token) {
        navigate("/login");
    }
    // }, [loggedIn, access_token, navigate]);

    return (
        <MainComponentContainer>
            {loggedIn ? (
                <div>
                    <UserDataTableContainer>
                        <UserDataTable />
                    </UserDataTableContainer>
                </div>
            ) : (
                <div>
                    <Unauthorized />
                </div>
            )}
        </MainComponentContainer>
    );
};

export default NonAttempters;