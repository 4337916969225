import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Divider, IconButton, Tooltip } from "@mui/material";
import { IoArrowBackCircle } from "react-icons/io5";
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { FaIdCard, FaCrown } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";
import {
  UserDataContainer,
  ProfileHeaderContainer,
  PDFDetailsContainer,
  BorderLinearProgress,
  AccordionContainer,
} from "../../Global.styled";

import { useAuth } from "../../Auth/Auth";
import UserStatCards from "./UserStatCards";
import {
  BarChartsContainer,
  EngagementReportCard,
  IconText,
  IconTextSubed,
  PDFButton,
  PDFButtonsContainer,
  PDFEngagementDataContainer,
  ProfileMainDataContainer,
  UserDetailsContainer,
  UserStatContainer,
  WindowgridWrapper,
} from "./index.styled";
import CatsPDFsBarchart from "./CatsPDFsBarchart";
import { Email, Phone } from "@mui/icons-material";
import API from "../../API";
import { toast } from "react-hot-toast";
import SaudiTime from "./SaudiTime";
import {
  Accordion,
  AccordionItem,
  CircularProgress,
  Skeleton,
} from "@nextui-org/react";

const UserMoreData = () => {
  const { loggedIn, access_token } = useAuth();
  const nav = useNavigate();
  const { id } = useParams();

  const [user, setUser] = useState(null);
  const [engagesPdfs, setEngagesPdfs] = useState([]);
  const [activePdf, setActivePdf] = useState({ uuid: "", name: "" });
  const [activeButton, setActiveButton] = useState(null); //to show the active button with border
  const [activeCategory, setActiveCategory] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [PdfDataLoading, setPdfDataLoading] = useState(false);
  const [activePdfEngagement, setActivePdfEngagement] = useState(null);
  const current_user = JSON.parse(
    localStorage.getItem("current_user")
  ).current_user;
  useEffect(() => {
    const fetchUserData = async () => {
      if (access_token) {
        try {
          setLoading(true);
          const userData = await API.getSingleUser(id, access_token);
          setLoading(false);
          setUser(userData);
          console.log("userdata", userData);
          setEngagesPdfs(
            userData?.engagements?.total_pdf_files_engaged?.map(
              (item) => item.pdf_files
            )
          );
          const firstCategory =
            userData?.engagements?.total_pdf_files_engaged?.[0];
          setActiveCategory({
            CatId: firstCategory?.category_id,
            CatName: firstCategory?.category_name,
          });
          const firstPdf =
            userData?.engagements?.total_pdf_files_engaged?.[0]?.pdf_files?.[0];
          if (firstPdf) {
            setActivePdf({ uuid: firstPdf.pdf_uuid, name: firstPdf.pdf_name });
            setActiveButton(firstPdf.pdf_uuid);
          }
        } catch (error) {
          setLoading(false);
          toast.error("Error: failed to fetch the user's data");
          console.log("Error: failed to fetch the user's data", error);
        }
      }
    };
    fetchUserData();
  }, [
    id,
    access_token,
    // user?.engagements?.subscription_status,
    // user?.engagements?.total_pdf_files_engaged,
  ]);

  useEffect(() => {
    //for getting individual pdf's engagement details
    const fetchPdfData = async () => {
      if (access_token && activePdf.uuid) {
        try {
          setPdfDataLoading(true);
          const pdfData = await API.getPdfData(
            id,
            activePdf.uuid,
            access_token
          );
          setPdfDataLoading(false);
          setActivePdfEngagement(pdfData);
        } catch (error) {
          setPdfDataLoading(false);
          console.log("Error: failed to fetch the PDF data", error);
        }
      }
    };
    fetchPdfData();
  }, [id, activePdf.uuid, access_token]);

  const engagementsData = {
    //data sent to <UserStatCards/> as obj
    crct_ans_percent: user?.engagements?.correct_answer_percentage,
    crct_ans_count: user?.engagements?.correct_answer_count,
    incrct_ans_count: user?.engagements?.incorrect_answer_count,
    mins_spent: user?.engagements?.total_minutes_spent_on_quizzes,
    total_questions_attempted: user?.engagements?.total_questions_attempted,
    total_quizzes_attempted: user?.engagements?.total_quizzes_attempted,
    free_credits_used: user?.engagements?.total_free_credits_used,
  };

  setInterval(() => {
    if (!loggedIn) {
      //if not logged in
      return (
        <div className="flex justify-center mt-20 text-red-600">
          <div>
            Error: Unauthenticated!! Please{" "}
            <Link className="font-bold text-blue-800" to={`/login`}>
              Login
            </Link>{" "}
          </div>
        </div>
      );
    }
  }, 3000);

  setInterval(() => {
    if (!user) {
      return (
        <div>
          <div className="flex gap-3 flex-col items-center justify-center min-h-screen text-gray-600 bg-gray-100">
            <h1 className="text-9xl font-bold">Error!</h1>
            <p className="mt-2 text-xl">Failed to get this page</p>
            <p className="mt-2 text-md">Unexpected error occurred.</p>
            <p className="mt-2 text-md">
              Please check your Internet Connection and try again.
            </p>
            <div className="m-6">
              <Button
                onClick={() => window.location.reload()}
                variant="contained"
              >
                Retry
              </Button>
            </div>
          </div>
        </div>
      );
    }
  }, 3000);

  return (
    <div style={{ minWidth: "600px" }}>
      {Loading ? (
        <div className="fixed top-0 z-[1000] w-screen">
          <BorderLinearProgress />
        </div>
      ) : null}
      <UserDataContainer>
        <Tooltip title="go-back" enterDelay={500}>
          <IconButton
            onClick={() =>
              nav(
                `${
                  current_user.non_attempter === false ? "/" : "/non_attempters"
                }`
              )
            }
            style={{
              position: "absolute",
              top: "65px",
              margin: "10px",
              backgroundColor: "lightgray",
              color: "black",
              left: window.innerWidth <= 768 ? "0" : "initial",
            }}
          >
            <IoArrowBackCircle size={30} />
          </IconButton>
        </Tooltip>

        <ProfileHeaderContainer>
          {/* <ProfileImageContainer>
            <img
              height={150}
              width={150}
              className="rounded-full"
              src={image}
              alt={user.name}
            />
          </ProfileImageContainer> */}
          <ProfileMainDataContainer>
            <div>
              <h1 className="text-5xl font-semibold min-w">
                {current_user?.name}
              </h1>
            </div>

            <UserDetailsContainer>
              <IconText>
                <Email />
                {current_user?.email}
              </IconText>
              <IconText>
                <Phone />
                {current_user?.phone}
              </IconText>
              <IconText>
                <FaIdCard size={20} /> {current_user?.p_id}
              </IconText>
              {current_user.non_attempter === true ? null : (
                <>
                  <IconText>
                    <MdOutlineAccessTimeFilled size={22} /> {current_user?.time}
                    mins
                  </IconText>
                  <IconText>
                    <SaudiTime
                      dateString={current_user?.first_engagement}
                      icon={true}
                    />
                  </IconText>
                </>
              )}

              <div>
                {user?.engagements?.subscription_status ? (
                  <div>
                    <IconTextSubed>
                      <FaCrown size={20} color="#e63e6d" /> SUBSCRIBED
                    </IconTextSubed>
                  </div>
                ) : (
                  <div>
                    <IconText>
                      <FiInfo color="red" />
                      Not Subscribed
                    </IconText>
                  </div>
                )}
              </div>
            </UserDetailsContainer>
          </ProfileMainDataContainer>
        </ProfileHeaderContainer>
      </UserDataContainer>
      {current_user.non_attempter === false ? (
        <>
          <Divider />
          <p className="underlineText text-center font-bold text-lg">
            Have a glance at the performance
          </p>
          <UserStatContainer className="userStatContainer">
            <UserStatCards engagementsData={engagementsData} />
          </UserStatContainer>

          <PDFDetailsContainer className="PDFDetailsContainer">
            <AccordionContainer>
              <Accordion variant="splitted" defaultExpandedKeys={["0"]}>
                {user?.engagements?.total_pdf_files_engaged?.map(
                  (category, index) => (
                    <AccordionItem
                      key={index}
                      aria-label={`Accordion ${index}`}
                      title={category.category_name}
                      onPress={() => {
                        const selectedCategory = {
                          CatId: category.category_id,
                          CatName: category.category_name,
                        };
                        setActiveCategory(selectedCategory);
                        const firstPdfInCategory = category?.pdf_files?.[0];
                        if (firstPdfInCategory) {
                          setActivePdf({
                            uuid: firstPdfInCategory.pdf_uuid,
                            name: firstPdfInCategory.pdf_name,
                          });
                          setActiveButton(firstPdfInCategory.pdf_uuid);
                        }
                      }}
                    >
                      <Divider />
                      <PDFButtonsContainer className="PDFButtonsContainer">
                        {category?.pdf_files?.map((pdf, index) => (
                          <div key={index} className="text-black">
                            <PDFButton
                              onClick={() => {
                                setActivePdf({
                                  uuid: pdf?.pdf_uuid,
                                  name: pdf?.pdf_name,
                                });
                                setActiveButton(pdf?.pdf_uuid);
                              }}
                              style={{
                                border:
                                  activeButton === pdf?.pdf_uuid
                                    ? "2px solid darkblue"
                                    : "none",
                              }}
                            >
                              {pdf.pdf_name}
                            </PDFButton>
                          </div>
                        ))}
                      </PDFButtonsContainer>
                    </AccordionItem>
                  )
                )}
              </Accordion>
            </AccordionContainer>

            <PDFEngagementDataContainer>
              <p className="font-semibold text-xl text-center text-blue-950 my-2">
                {activePdf.name}
              </p>
              <Divider />

              {PdfDataLoading ? (
                <div className="flex items-center justify-center h-[80%]">
                  <CircularProgress size="lg" />
                </div>
              ) : (
                <WindowgridWrapper>
                  {activePdfEngagement?.engagements ? (
                    <>
                      <EngagementReportCard>
                        <span>
                          {activePdfEngagement?.engagements
                            ?.correct_per_pdf || (
                            <span className="text-red-400 font-bold">
                              Data not available
                            </span>
                          )}
                        </span>
                        <span>Correct Answers</span>
                      </EngagementReportCard>
                      <EngagementReportCard>
                        <span>
                          {Number(
                            activePdfEngagement?.engagements?.ques_per_pdf
                          ) -
                            Number(
                              activePdfEngagement?.engagements?.correct_per_pdf
                            ) || (
                            <span className="text-red-400 font-bold">
                              Data not available
                            </span>
                          )}
                        </span>
                        <span>Incorrect Answers</span>
                      </EngagementReportCard>
                      <EngagementReportCard>
                        <span>
                          {activePdfEngagement?.engagements
                            ?.minutes_per_pdf || (
                            <span className="text-red-400 font-bold">
                              Data not available
                            </span>
                          )}
                        </span>
                        <span>Mins Spent</span>
                      </EngagementReportCard>
                      <EngagementReportCard>
                        <span>
                          {activePdfEngagement?.engagements?.ques_per_pdf || (
                            <span className="text-red-400 font-bold">
                              Data not available
                            </span>
                          )}
                        </span>
                        <span>Total Questions</span>
                      </EngagementReportCard>
                      <EngagementReportCard>
                        <span>
                          {activePdfEngagement?.engagements?.quiz_count || (
                            <span className="text-red-400 font-bold">
                              Data not available
                            </span>
                          )}
                        </span>
                        <span>Quiz Count</span>
                      </EngagementReportCard>
                      <EngagementReportCard>
                        <span>
                          {
                            activePdfEngagement?.engagements
                              ?.free_credits_per_pdf
                          }
                        </span>
                        <span>Free Credits Used</span>
                      </EngagementReportCard>
                      <EngagementReportCard>
                        <span>
                          <SaudiTime
                            dateString={
                              activePdfEngagement?.engagements
                                ?.first_attempt_date
                            }
                            icon={false}
                            time_new_line={true}
                          />
                        </span>
                        <span>First Attempted Date</span>
                      </EngagementReportCard>
                    </>
                  ) : (
                    <Skeleton className="rounded-lg">
                      <div className="h-24 rounded-lg bg-secondary"></div>
                    </Skeleton>
                  )}
                </WindowgridWrapper>
              )}
            </PDFEngagementDataContainer>
          </PDFDetailsContainer>

          <BarChartsContainer>
            <CatsPDFsBarchart
              activeCategory={activeCategory}
              engagesPdfs={engagesPdfs}
            />
          </BarChartsContainer>
        </>
      ) : (
        <p className="text-4xl font-semibold text-center m-10">
          This user has registered an account but has not yet attempted any
          quizzes.🤔
        </p>
      )}
    </div>
  );
};
export default UserMoreData;
