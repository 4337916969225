import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import { Navbar, Sidebar, Categories, Dashboard, Insights, NonAttempters } from "./components";
import { useStateContext } from "./contexts/ContextProvider";
import Login from "./Auth/Login";
import { AuthProvider } from "./Auth/Auth";
import UserMoreData from "./components/Dashboard/UserDataMore";
import PageNotFound from "./components/PageNotFound";
import { NextUIProvider } from "@nextui-org/react";
import Test from "./Test";

const App = () => {
  const { activeMenu } = useStateContext();

  return (
    <NextUIProvider>
      <AuthProvider>
        <BrowserRouter>
          <div className="flex relative ">
            {activeMenu ? (
              <div className="w-72 fixed sidebar bg-white">
                <Sidebar />
              </div>
            ) : (
              <div className="w-0 ">
                <Sidebar />
              </div>
            )}
            <div
              className={` bg-main-bg min-h-screen w-full ${
                activeMenu ? "md:ml-72" : "flex-2"
              }`}
            >
              <div className="fixed md:static bg-main-bg  navbar w-full">
                <Navbar />
              </div>
              <div>
                <Routes>
                  {/* <Route path='/' element={<Home/>} /> */}
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/categories" element={<Categories />} />
                  <Route path="/:id" element={<UserMoreData />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="*" element={<PageNotFound />} />
                  <Route path="/testing" element={<Test />} />
                  <Route path="/insights" element={<Insights />} />
                  <Route path="/non_attempters" element={<NonAttempters />} />
                </Routes>
              </div>
            </div>
          </div>
        </BrowserRouter>
      </AuthProvider>
    </NextUIProvider>
  );
};

export default App;
