import React, { useEffect, useState } from "react";
import { FaUsers, FaQuestionCircle, FaFilePdf } from "react-icons/fa";
import { MdTimer, MdCheckCircle, MdCancel } from "react-icons/md";
import { BsBarChartFill } from "react-icons/bs";
import { VscBracketError } from "react-icons/vsc";

import {
  TextWithBg,
  UsersSummaryBlock,
  UsersSummaryMainContainer,
} from "./index.styled";
import { useAuth } from "../../Auth/Auth";
import API from "../../API";
import { useStateContext } from "../../contexts/ContextProvider";
import { Tooltip } from "@mui/material";
import { Toaster, toast } from "react-hot-toast";

const UsersSummary = () => {
  const { setTotalPages } = useStateContext();

  const { access_token } = useAuth();
  const [engagementSummary, setEngagementSummary] = useState();
  const [showInHours, setShowInHours] = useState(true);
  useEffect(() => {
    const fetchEngagementSummary = async () => {
      if (access_token) {
        try {
          const res = await API.getEngagementSummary(access_token);
          setEngagementSummary(res);
          setTotalPages(engagementSummary?.total_users);
          // setTotalPages(
          //   Math.round(engagementSummary?.total_users / rowsPerPage)
          // );
        } catch (er) {
          toast.error("Failed to get User engagemets data.");
          console.log("Error in fetching users", er);
        }
      }
    };

    fetchEngagementSummary();
  }, [access_token, engagementSummary?.total_users, setTotalPages]);
  const percentage = Math.round(
    (engagementSummary?.correct_answer_count /
      engagementSummary?.total_questions_attempted) *
      100
  );
  const formatToHours = (minutes) => {
    const hours = minutes / 60;
    // const days = minutes / (60 * 24);and ${days.toFixed(2)} days
    return `${hours.toFixed(2)} hours`;
  };
  const formatToDays = (minutes) => {
    const days = minutes / (60 * 24);
    return `${days.toFixed(2)} days`;
  };

  return (
    <div>
      <UsersSummaryMainContainer>
        <UsersSummaryBlock style={{ backgroundColor: "#D6EAF8" }}>
          <div className="rounded-lg p-1 border-black ">
            <FaUsers className="border-blue-800" color="#3498DB" size={50} />
          </div>
          <div className="">
            <TextWithBg style={{ backgroundColor: "#3498DB" }}>
              {engagementSummary?.total_users ? (
                engagementSummary?.total_users
              ) : (
                <Tooltip title="Data unavailable due to an error!" followCursor>
                  <span className="flex justify-center items-center">
                    <VscBracketError size={20} />
                  </span>
                </Tooltip>
              )}
            </TextWithBg>
            <div>Total Users Count</div>
          </div>
        </UsersSummaryBlock>
        <UsersSummaryBlock
          style={{ backgroundColor: "#ffeed5", cursor: "pointer" }}
          onClick={() => setShowInHours((prev) => !prev)}
        >
          <div className="rounded-lg p-1 border-black ">
            <MdTimer className="border-blue-800" color="#F39C12" size={50} />
          </div>
          <div>
            <div
              className="p-2 rounded-lg text-white"
              style={{ backgroundColor: "#F39C12" }}
            >
              {showInHours ? (
                <span>
                  {engagementSummary?.total_minutes_spent ? (
                    formatToDays(engagementSummary?.total_minutes_spent)
                  ) : (
                    <Tooltip
                      title="Data unavailable due to an error!"
                      followCursor
                    >
                      <span className="flex justify-center items-center">
                        <VscBracketError size={20} />
                      </span>
                    </Tooltip>
                  )}
                </span>
              ) : (
                <span>
                  {engagementSummary?.total_minutes_spent ? (
                    formatToHours(engagementSummary?.total_minutes_spent)
                  ) : (
                    <Tooltip
                      title="Data unavailable due to an error!"
                      followCursor
                    >
                      <span className="flex justify-center items-center">
                        <VscBracketError size={20} />
                      </span>
                    </Tooltip>
                  )}
                </span>
              )}
            </div>
            <div>Total Time Spent</div>
          </div>
        </UsersSummaryBlock>
        <UsersSummaryBlock style={{ backgroundColor: "#f4e4fa" }}>
          <div className="rounded-lg p-1 border-black ">
            <BsBarChartFill
              className="border-blue-800"
              color="#8E44AD"
              size={50}
            />
          </div>
          <div>
            <TextWithBg style={{ backgroundColor: "#8E44AD" }}>
              {percentage ? (
                percentage + " %"
              ) : (
                <Tooltip title="Data unavailable due to an error!" followCursor>
                  <span className="flex justify-center items-center">
                    <VscBracketError size={20} />
                  </span>
                </Tooltip>
              )}
            </TextWithBg>
            <div>Total Performance</div>
          </div>
        </UsersSummaryBlock>
        <UsersSummaryBlock style={{ backgroundColor: "#f5efd5" }}>
          <div className="rounded-lg p-1 border-black ">
            <FaQuestionCircle
              className="border-blue-800"
              color="#F1C40F"
              size={50}
            />
          </div>
          <div>
            <TextWithBg style={{ backgroundColor: "#F1C40F" }}>
              {engagementSummary?.total_questions_attempted ? (
                engagementSummary?.total_questions_attempted
              ) : (
                <Tooltip title="Data unavailable due to an error!" followCursor>
                  <span className="flex justify-center items-center">
                    <VscBracketError size={20} />
                  </span>
                </Tooltip>
              )}
            </TextWithBg>
            <div>Questions Attended</div>
          </div>
        </UsersSummaryBlock>
        <UsersSummaryBlock style={{ backgroundColor: "#e8f8f5" }}>
          <div className="rounded-lg p-1 border-black ">
            <FaFilePdf className="border-blue-800" color="#1ABC9C" size={50} />
          </div>
          <div>
            <p className="bg-[#1ABC9C] p-1 py-2 text-white rounded-md font-medium">
              {engagementSummary?.most_attempted_pdf_name ? (
                engagementSummary?.most_attempted_pdf_name
              ) : (
                <Tooltip title="Data unavailable due to an error!" followCursor>
                  <span className="flex justify-center items-center">
                    <VscBracketError size={20} />
                  </span>
                </Tooltip>
              )}
            </p>
            <div>Most Attempted PDF</div>
          </div>
        </UsersSummaryBlock>

        <UsersSummaryBlock style={{ backgroundColor: "#D5F5E3" }}>
          <div className="rounded-lg p-1 border-black ">
            <MdCheckCircle
              className="border-blue-800"
              color="#27AE60"
              size={50}
            />
          </div>
          <div>
            <TextWithBg style={{ backgroundColor: "#27AE60" }}>
              {engagementSummary?.correct_answer_count ? (
                engagementSummary?.correct_answer_count
              ) : (
                <Tooltip title="Data unavailable due to an error!" followCursor>
                  <span className="flex justify-center items-center">
                    <VscBracketError size={20} />
                  </span>
                </Tooltip>
              )}
            </TextWithBg>
            <div>Correct Answers</div>
          </div>
        </UsersSummaryBlock>
        <UsersSummaryBlock style={{ backgroundColor: "#ffedeb" }}>
          <div className="rounded-lg p-1 border-black ">
            <MdCancel className="border-blue-800" color="#C0392B" size={50} />
          </div>
          <div>
            <TextWithBg style={{ backgroundColor: "#C0392B" }}>
              {engagementSummary?.incorrect_answer_count ? (
                engagementSummary?.incorrect_answer_count
              ) : (
                <Tooltip title="Data unavailable due to an error!" followCursor>
                  <span className="flex justify-center items-center">
                    <VscBracketError size={20} />
                  </span>
                </Tooltip>
              )}
            </TextWithBg>
            <div>Incorrect Answers</div>
          </div>
        </UsersSummaryBlock>
        <Toaster />
      </UsersSummaryMainContainer>
    </div>
  );
};
export default UsersSummary;
