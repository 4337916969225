import React from "react";
import { HiUsers } from "react-icons/hi";
import { BiSolidCategory } from "react-icons/bi";
import { BsGraphUp } from "react-icons/bs";
import { FaUserSlash } from "react-icons/fa";

export const links = [
  {
    title: "Dashboard",
    links: [
      {
        name: "Users",
        link: "",
        icon: <HiUsers size={25}/>,
      },
      {
        name: "categories",
        link: "categories",
        icon: <BiSolidCategory size={25}/>,
      },
      {
        name: "Insights",
        link: "insights",
        icon: <BsGraphUp size={25}/>,
      },
      {
        name: "Non Attempters",
        link: "non_attempters",
        icon: <FaUserSlash size={25} />,
      },
    ],
  },
];

export const PDFPerformances = {
  pdf1: {
    correct_count: 5,
    correct_percentage: 50.0,
    incorrect_count: 5,
    ques_count: 10,
    total_minutes: 10,
    total_quiz_attempted: 1,
  },
  pdf2: {
    correct_count: 9,
    correct_percentage: 90.0,
    incorrect_count: 1,
    ques_count: 10,
    total_minutes: 4,
    total_quiz_attempted: 1,
  },
  pdf3: {
    correct_count: 6,
    correct_percentage: 60.0,
    incorrect_count: 4,
    ques_count: 10,
    total_minutes: 1,
    total_quiz_attempted: 1,
  },
  pdf: {
    correct_count: 9,
    correct_percentage: 90.0,
    incorrect_count: 1,
    ques_count: 10,
    total_minutes: 2,
    total_quiz_attempted: 1,
  },
  pdf5: {
    correct_count: 1,
    correct_percentage: 10.0,
    incorrect_count: 10,
    ques_count: 10,
    total_minutes: 3,
    total_quiz_attempted: 1,
  },
};
