import React from "react";
import { useAuth } from "../../Auth/Auth";
import { useStateContext } from "../../contexts/ContextProvider";
import {
  Button,
  CircularProgress,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Snippet,
  useDisclosure,
} from "@nextui-org/react";
import { useState } from "react";
import API from "../../API";
import { Toaster, toast } from "react-hot-toast";
import { CgNametag } from "react-icons/cg";
import { TbFileDescription } from "react-icons/tb";
import { IoClose } from "react-icons/io5";

const EditCategory = ({ isOpen, setisOpen, category }) => {
  const { access_token } = useAuth();
  const { refresh, setRefresh } = useStateContext();
  const { onOpenChange } = useDisclosure();

  const [catName, setCatName] = useState("");
  const [catDescription, setCatDescription] = useState("");
  const [creating, setCreating] = useState(false);

  const handleCloseModal = (onClose) => {
    setisOpen(false);
    onClose();
  };

  const handleDone = async (onClose) => {
    if (
      catName.length > 0 ||
      (category.editType === "description" && catDescription.length > 0)
    ) {
      try {
        if (access_token) {
          setCreating(true);
          await API.editCategory(
            access_token,
            category.id,
            catName,
            catDescription,
            category.editType
          );

          setCreating(false);
        }
      } catch (error) {
        setCreating(false);
        toast.error("Editing category failed!");
        console.log("error in editing category name", error);
      }

      setRefresh(refresh + 1);
      setisOpen(false);
      onClose();
    } else {
      toast.error("Type something to rename.", {
        style: {
          border: "1px solid red",
          padding: "16px",
          color: "red",
        },
        iconTheme: {
          primary: "red",
          secondary: "white",
        },
      });
    }
  };
  return (
    <div>
      {creating ? (
        <div className="flex h-[80vh] justify-center items-center">
          <div className="flex justify-center shadow-md items-center rounded-md  flex-col gap-1 p-2 border">
            <span>
              <CircularProgress size="lg" label={"Editing..."} />
            </span>
          </div>
        </div>
      ) : (
        ""
      )}
      <>
        <Modal
          hideCloseButton
          backdrop="blur"
          isOpen={isOpen}
          onOpenChange={onOpenChange}
        >
          <ModalContent>
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1">
                  <div className="flex justify-start items-start flex-col">
                    <span>
                      Editing{" "}
                      <span className="font-bold">
                        <Snippet
                          style={{ backgroundColor: "transparent" }}
                          symbol=""
                        >
                          <span className="font-bold text-xl">
                            {category?.name}
                          </span>
                        </Snippet>
                      </span>
                    </span>
                    <Snippet
                      style={{ backgroundColor: "transparent" }}
                      symbol=""
                      className="text-sm font-normal p-0"
                    >
                      <span className="text-slate-700 text-md ">
                        {category?.desc}
                      </span>
                    </Snippet>
                  </div>
                </ModalHeader>
                <ModalBody>
                  <div className="absolute top-0 right-0 m-1">
                    <Button
                      onPress={() => handleCloseModal(onClose)}
                      variant="light"
                      isIconOnly
                    >
                      <IoClose size={25} />
                    </Button>
                  </div>
                  {category.editType === "description" ? (
                    <Input
                      value={catDescription}
                      onChange={(e) => setCatDescription(e.target.value)}
                      endContent={
                        <TbFileDescription className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                      }
                      label="Decription"
                      placeholder="Edit the Description"
                      type="text"
                      variant="bordered"
                    />
                  ) : (
                    <Input
                      value={catName}
                      onChange={(e) => setCatName(e.target.value)}
                      autoFocus
                      endContent={
                        <CgNametag className="text-2xl text-default-400 pointer-events-none flex-shrink-0" />
                      }
                      label="Name"
                      placeholder="Edit the Name"
                      variant="bordered"
                    />
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="default"
                    variant="flat"
                    onPress={() => handleCloseModal(onClose)}
                  >
                    Close
                  </Button>
                  <Button color="primary" onPress={() => handleDone(onClose)}>
                    Done
                  </Button>
                </ModalFooter>
              </>
            )}
          </ModalContent>
          <Toaster />
        </Modal>
      </>
    </div>
  );
};

export default EditCategory;
