import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { useAuth } from "../Auth/Auth";
import { Box } from "@mui/system";
import { Logout } from "@mui/icons-material";

import avatar from "../data/avatar.jpg";
import { MdKeyboardArrowDown } from "react-icons/md";
import { Popover, PopoverContent, PopoverTrigger } from "@nextui-org/react";

const UserProfile = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    auth.logout();
    navigate("/login", { replace: true });
  };

  return (
    <div className="select-none">
      {auth.loggedIn ? (
        <>
          <Box>
            <Popover>
              <PopoverTrigger>
                <Tooltip enterDelay={500} title="Logout">
                  <div size="small" sx={{ ml: 2 }}>
                    {/* <Avatar sx={{width:32,height:32}}>A</Avatar> */}
                    <div className="flex items-center mt-2 ml-10 gap-2 cursor-pointer p-1 ">
                      <img
                        className="rounded-full w-8 h-8"
                        src={avatar}
                        alt="user-profile"
                      />

                      <p>
                        <span className="text-gray-400 text-14">Hi,</span>{" "}
                        <span className="text-gray-400 font-bold ml-1 text-14">
                          Admin
                        </span>
                      </p>
                      <MdKeyboardArrowDown className="text-gray-400 text-14" />
                    </div>
                  </div>
                </Tooltip>
              </PopoverTrigger>
              <PopoverContent>
                <div className="px-1 py-2 cursor-pointer transition-all">
                  <div
                    className="text-small font-bold  select-none"
                    onClick={handleLogout}
                  >
                    <span className="flex items-center justify-center ">
                      {" "}
                      <Logout fontSize="small" /> Logout
                    </span>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </Box>
        </>
      ) : (
        <div>
          {!auth.loggedIn && (
            <Link to="/login">
              <button className="bg-gradient-to-r from-indigo-500 to-blue-500 text-white font-bold py-2 px-7 rounded-md mt-2 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150">
                Login
              </button>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default UserProfile;
